import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../Account/AuthContext";
import { Link } from "react-router-dom";
import "./style.css";
import Offcanvas from "../Offcanvas/offcanvas";
import SimchasDropDown from "../SImchasMenu/SimchasMenu";
import HeartIcon from "../../assets/icons/HeartIcon";
import CartIcon from "../../assets/icons/CartIcon";
import UserIcon from "../../assets/icons/UserIcon";
import NavLogo from "../../assets/logo/nav-logo";
import useViewportWidth from "../../hooks/useViewportWidth";
import CartMenu from "../CartMenu/CartMenu";
import { useCategories } from "../../context/categoriesContext";
import ContactUsModal from "../ContactUsModal/ContactUsModal";
import { useWishlist } from "../../context/wishlistContext";
import { useCart } from "../../context/cartContext";
import categoriesRequests from "../../requests/categories";
import piningicon from "../../assets/images/icons/Pining.svg";
import OccasionsMenu from "../SImchasMenu/OccasionsMenu";
import CustomModal from "../Categories/CustomModal";

const NavBar = () => {
    const { authenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isCartMenuOpen, setIsCartMenuOpen] = useState(null);
    const width = useViewportWidth();
    const toggleCart = () => setIsCartOpen(!isCartOpen);
    const { wishlistQty } = useWishlist();
    const { cartQty } = useCart();
    const [modalOpen, setModalOpen] = useState(false);
    const [customModalOpen, setCustomModalOpen] = useState(false);

    const [ocassionIsHover, setOcassionIsHover] = useState(null);
    const [simchasIsHover, setSimchasIsHover] = useState(null);
    const [selectcategories, setSelectCategories] = useState(null);
    const [parentCategory, setParentCategory] = useState(null);

    const fetchSelectedCategory = async () => {
        try {
            const categoryData = await categoriesRequests.getselectcategories();
            if (!categoryData.success) return;
            setSelectCategories(categoryData.data);

            if (categoryData.parentCategoryName) {
                setParentCategory(categoryData.parentCategoryName);
            } else {
                setParentCategory("");
            }
            // const parentCategoryName = await categoriesRequests.getCategoryParent(categoryData.data.id);
            // if (parentCategoryName?.parentCategoryName) {
            //     setParentCategory(parentCategoryName.parentCategoryName);
            // } else {
            //     setParentCategory("");
            // }
        } catch (error) {
            console.error("Failed to fetch selected category:", error);
        }
    };

    useEffect(() => {
        fetchSelectedCategory();
        renderCategoryLink();
    }, []);

    const renderCategoryLink = () => {
        if (!selectcategories) return false;

        const category = selectcategories.friendlyUrlName;
        const parentCategoryName = parentCategory;

        let categoryPath = "";

        if (category) {
            categoryPath = parentCategoryName
                ? `categories/${encodeURIComponent(parentCategoryName.toLowerCase())}?sub=${encodeURIComponent(
                      category.toLowerCase()
                  )}`
                : `categories/${encodeURIComponent(category.toLowerCase())}`;
        }
        if (categoryPath) {
            return (
                <Link className="header-pining link m-0" to={categoryPath}>
                    <span className="header-pining-p">{category || ""}</span>
                    <img width="10" height="13" src={piningicon} alt="Pining Icon" />
                </Link>
            );
        }
    };

    const ScrollToHash = () => {
        const location = useLocation();
    
        React.useEffect(() => {
            if (location.hash) {
                const element = document.getElementById(location.hash.replace("#", ""));
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        }, [location]);
    
        return null;
    };

    const isActive = (path) => {
        return location.pathname === path || location.pathname.startsWith(path);
    };

    return (
        <>
            <ScrollToHash />
            <div className="header-container">
                <div className="header-sub-container">
                    <div
                        className="logo-wrap"
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <NavLogo width={280} height={37} />
                        {/* <p className="beta-logo">BETA</p> */}
                    </div>
                    <div className="links-icons-wrap" style={{ display: "flex" }}>
                        <div className="links-wrap">
                            {renderCategoryLink()}
                            <Link className={`link ${isActive('/') && location.pathname === '/' ? 'active' : ''}`} to="/">
                                Home
                            </Link>

                            <div
                                className={`link simchas-link cursor-pointer`}
                                onMouseEnter={() => {
                                    setSimchasIsHover(true);
                                }}
                                onMouseLeave={() => {
                                    setSimchasIsHover(false);
                                }}
                            >
                                Simchas
                                <SimchasDropDown simchasIsHover={simchasIsHover} />
                            </div>
                            <div
                                className="link ocassions-link cursor-pointer"
                                onMouseEnter={() => {
                                    setOcassionIsHover(true);
                                }}
                                onMouseLeave={() => {
                                    setOcassionIsHover(false);
                                }}
                            >
                                Occasions
                                <span
                                    className={`ocassions-drop-down mt-1
                                        ${
                                            ocassionIsHover === true
                                                ? "active"
                                                : ocassionIsHover === false
                                                ? "hidden"
                                                : ocassionIsHover === null
                                                ? "display-none"
                                                : ""
                                        }
                                    `}
                                >
                                    <OccasionsMenu />
                                    {/* <Link
                                        className="drop-down-link"
                                        style={{ marginBottom: "10px" }}
                                        to="categories/others?sub=חתן-בראשית"
                                    >
                                        חתן-בראשית
                                    </Link>
                                    <Link
                                        className="drop-down-link"
                                        // style={{ marginBottom: "10px" }}
                                        to="categories/others?sub=chanukah"
                                    >
                                        Chanukah
                                    </Link> */}
                                </span>
                            </div>
                            <Link className={`link ${isActive('/categories/videos') ? 'active' : ''}`} to="categories/videos">
                                Videos
                            </Link>

                            <Link
                                onClick={() => setCustomModalOpen(true)}
                                className="link"
                                data-bs-toggle="modal"
                                data-bs-target="#customModal"
                            >
                                Custom
                            </Link>
                            <Link
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                                className="link"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                            >
                                Contact Us
                            </Link>
                        </div>
                        <div className="icons-wrap">
                            <Link to="/wishlist">
                                <div className="nav-icon heart-icon" style={{ marginRight: "5px" }}>
                                    <HeartIcon />
                                    {wishlistQty ? (
                                        <span className="wishlist-length">{wishlistQty}</span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            </Link>
                            <div>
                                <div
                                    className="nav-icon cart-icon"
                                    onClick={() => {
                                        setIsCartMenuOpen(true);
                                    }}
                                >
                                    <CartIcon />
                                    {cartQty ? <span className="cart-length">{cartQty}</span> : <span></span>}
                                </div>
                                <CartMenu setIsCartMenuOpen={setIsCartMenuOpen} isCartMenuOpen={isCartMenuOpen} />
                            </div>
                            <Link className="auth-link" to={authenticated ? `/account/OrderHistory` : `/login`}>
                                <div className="nav-icon">
                                    <UserIcon />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="responsive-sub-container align-items-center">
                    <Offcanvas
                        authenticated={authenticated}
                        wishlist={wishlistQty}
                        setModalOpen={setModalOpen}
                        modalOpen={modalOpen}
                    />
                    <div
                        className="logo-wrap"
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <NavLogo width={width > 770 ? 253 : 150} height={33} />
                        {/* <p className="beta-logo beta-logo-res">BETA</p> */}
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                       
                    >
                        <div className="nav-icon cart-icon" onClick={() => { setIsCartMenuOpen(true); }}>
                            <CartIcon />
                            {cartQty ? <span className="cart-length">{cartQty}</span> : <span></span>}
                        </div>
                        <CartMenu setIsCartMenuOpen={setIsCartMenuOpen} isCartMenuOpen={isCartMenuOpen} />
                    </div>
                </div>
                {/* <CartMenu setIsCartMenuOpen={setIsCartMenuOpen} isCartMenuOpen={isCartMenuOpen} /> */}
            </div>
            <ContactUsModal setModalOpen={setModalOpen} modalOpen={modalOpen} />
            <CustomModal customModalOpen={customModalOpen} setCustomModalOpen={setCustomModalOpen} />
        </>
    );
};

export default NavBar;
