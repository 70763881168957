import React, { useRef } from "react";
// import { IoMenu } from "react-icons/io5";
import "./style.css";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import BabyBoyIcon from "../../assets/icons/baby-boy-icon";
import KidDushIcon from "../../assets/icons/kid-dush-icon";
import ScissorIcon from "../../assets/icons/scissor-icon";
import BarIcon from "../../assets/icons/Bar-icon";
import RingIcon from "../../assets/icons/Ring-icon";
import BottleIcon from "../../assets/icons/bottle-icon";
import WeddingIcon from "../../assets/icons/wedding";
import FlowerIcon from "../../assets/icons/flower";
import VideoIcon from "../../assets/icons/videos";
import OtherIcon from "../../assets/icons/others";
import HeartIcon from "../../assets/icons/HeartIcon";
import OffcanvasContactIcon from "../../assets/icons/offcanvas-contact-icon";
import UserIcon from "../../assets/icons/UserIcon";

const Offcanvas = ({ authenticated, wishlist, setModalOpen }) => {
    const menuItems = [
        {
            icon: <BabyBoyIcon />,
            text: "BabyBoy",
            slug: "/baby-boy",
        },
        {
            icon: <KidDushIcon />,
            text: "Kidush",
            slug: "/kiddush",
        },
        {
            icon: <ScissorIcon />,
            text: "Upsherin",
            slug: "/upsherin",
        },
        {
            icon: <BarIcon />,
            text: "Bar Mitzwah",
            slug: "/bar-mitzvah",
        },
        {
            icon: <RingIcon />,
            text: "Tenoyem",
            slug: "/tenoyem",
        },
        {
            icon: <BottleIcon />,
            text: "Bavarfen",
            slug: "/bavarfen",
        },
        {
            icon: <WeddingIcon />,
            text: "Wedding",
            slug: "/wedding",
        },
        {
            icon: <FlowerIcon />,
            text: "Shiva Bruches",
            slug: "/sheva-bruches",
        },
        {
            icon: <VideoIcon />,
            text: "Videos",
            slug: "/videos",
        },
        {
            icon: <OtherIcon />,
            text: "Others",
            slug: "/others",
        },
    ];

    const closeButtonRef = useRef(null);

    const handleClose = () => {
        if (closeButtonRef.current) {
            closeButtonRef.current.click();
        }
    };

    return (
        <>
            <div>
                <div className="offcanvas-container">
                    <div
                        className="offcanvas-button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasWithBackdrop"
                        aria-controls="offcanvasWithBackdrop"
                    >
                        {/* <IoMenu fontSize={20} /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"></path>
                        </svg>
                    </div>
                    <div
                        class="offcanvas offcanvas-start"
                        tabindex="-1"
                        id="offcanvasWithBackdrop"
                        aria-labelledby="offcanvasWithBackdropLabel"
                    >
                        <div class="offcanvas-header position-relative">
                            <div className="offcanvas-links-wrap">
                                <Link
                                    to="/wishlist"
                                    className="offcanvas-header-links heart-wrap"
                                    onClick={() => handleClose()}
                                >
                                    <HeartIcon />
                                    <Link to="/wishlist" className="offcanvas-link">
                                        Wishlist
                                    </Link>
                                    {wishlist ? <span className="wishlist-length">{wishlist}</span> : <span></span>}
                                </Link>
                                <Link
                                    className="offcanvas-header-links"
                                    onClick={() => {
                                        setModalOpen(false);
                                        handleClose();
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                >
                                    <OffcanvasContactIcon />
                                    <Link className="offcanvas-link">Contact Us</Link>
                                </Link>
                                <Link
                                    to={authenticated ? `/account/accountprofile` : `/login`}
                                    className="offcanvas-header-links"
                                    onClick={() => handleClose()}
                                >
                                    <UserIcon />
                                    <Link
                                        className="offcanvas-link"
                                        to={authenticated ? `/account/accountprofile` : `/login`}
                                    >
                                        Account
                                    </Link>
                                </Link>
                            </div>
                            <button
                                type="button"
                                class="btn-close text-reset position-absolute"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                ref={closeButtonRef}
                            ></button>
                        </div>
                        <div class="offcanvas-body">
                            <div className="offcanvas-menu-wrap">
                                {menuItems.map((item, index) => (
                                    <Link
                                        to={`categories${item?.slug}`}
                                        key={index}
                                        className="offcanvas-menu"
                                        onClick={() => handleClose()}
                                    >
                                        <span>{item.icon}</span>
                                        {item.text}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Offcanvas;
