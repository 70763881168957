import React from "react";

const OffcanvasContactIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.98 12.463">
                <g transform="translate(-89.521 -89.3)">
                    <path
                        d="M.632,11.063A7.8,7.8,0,0,0,3.254,9.646a7.38,7.38,0,0,0,3.036.638c3.475,0,6.29-2.3,6.29-5.142S9.765,0,6.29,0,0,2.3,0,5.142A4.838,4.838,0,0,0,2.3,9.117a9.672,9.672,0,0,1-1.67,1.944v0m0,.7a.7.7,0,0,1-.7-.7v0a.7.7,0,0,1,.233-.521A9.126,9.126,0,0,0,1.34,9.27,5.376,5.376,0,0,1-.7,5.142,5.375,5.375,0,0,1,1.4.964,7.719,7.719,0,0,1,6.29-.7,7.719,7.719,0,0,1,11.181.964a5.375,5.375,0,0,1,2.1,4.178,5.375,5.375,0,0,1-2.1,4.178A7.719,7.719,0,0,1,6.29,10.984a8.2,8.2,0,0,1-2.927-.534A8.5,8.5,0,0,1,.843,11.731.7.7,0,0,1,.632,11.763Z"
                        transform="translate(90.221 90)"
                    ></path>
                    <path
                        d="M1.558-.854a.524.524,0,0,0,1.047,0A.524.524,0,0,0,1.558-.854ZM1.087-4.062c.007-.591.636-.853,1.167-.853s1.159.15,1.159.83c0,.524-.434.613-.838.8-.531.224-.912.494-.912,1.518l.83.007c0-.5.1-.7.441-.853.441-.254,1.339-.4,1.339-1.466.015-1.144-.972-1.571-2-1.571s-2,.471-2,1.586Z"
                        transform="translate(94.239 98.525)"
                    ></path>
                </g>
            </svg>
        </div>
    );
};

export default OffcanvasContactIcon;
