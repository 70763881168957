function hasHebrewCharacters(text) {
    if (!text) return false;
    return /[\u0590-\u05FF]/.test(text);
}

function reverseNumbers(str) {
    const numsAndLetters = str.match(/([^0-9]|^\:)+|([0-9]|:)+/g);

    const numsRegex = /[0-9]/;

    return numsAndLetters.map((item) => (numsRegex.test(item) ? item.split("").reverse().join("") : item)).join('');
}

// add double quotes (only for hebrew date)
function addDoubleQuotes(txt){
    if(!txt || !txt.trim()) return '';
    let l = txt.length - 1;
    // double quote before last letter
    let _txt = txt.substring(0, l) + "\"" + txt.substring(l);
    // check if the first part has two characters and add a (") in the middle
    let _firstWord = txt.split(' ')[0];
    if(_firstWord.length === 2){
        if(!(/\u05D9/.test(_firstWord))){ // doesn't includes Yod character 
            _txt = _txt[0] + "\"" +  _txt.substring(1);
        } 
    }

    return _txt;
}

function convertNameToFriendlyUrlName(categoryName){
    let friendly = categoryName.toLowerCase();
    friendly = friendly.replace(/['\+\/]/g, '');
    friendly = friendly.replace(/ /g, '-');

    return friendly;
}

export { 
    hasHebrewCharacters, 
    reverseNumbers,
    addDoubleQuotes,
    convertNameToFriendlyUrlName
};
