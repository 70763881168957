import React from "react";

const Friendly = ({ width, height }) => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Group_11028"
                data-name="Group 11028"
                width={width}
                height={height}
                viewBox="0 0 109.788 66.338"
            >
                <g id="Group_11021" data-name="Group 11021" transform="translate(39.768 0)">
                    <path
                        id="Path_10695"
                        data-name="Path 10695"
                        d="M682.475,229.914a15.087,15.087,0,1,1,15.087-15.087A15.1,15.1,0,0,1,682.475,229.914Zm0-28.391a13.3,13.3,0,1,0,13.3,13.3A13.319,13.319,0,0,0,682.475,201.523Z"
                        transform="translate(-667.388 -199.74)"
                    ></path>
                </g>
                <g id="Group_11022" data-name="Group 11022" transform="translate(20.535 32.805)">
                    <path
                        id="Path_10696"
                        data-name="Path 10696"
                        d="M654.277,362.089H598.094a6.226,6.226,0,0,1-5.977-7.962A35.729,35.729,0,0,1,604.7,335.8a35.476,35.476,0,0,1,42.971,0,35.73,35.73,0,0,1,12.583,18.325,6.226,6.226,0,0,1-5.977,7.962Zm-28.092-31.751a33.379,33.379,0,0,0-20.4,6.881,33.938,33.938,0,0,0-11.952,17.4,4.443,4.443,0,0,0,4.265,5.683h56.183a4.443,4.443,0,0,0,4.265-5.683,33.935,33.935,0,0,0-11.952-17.4A33.378,33.378,0,0,0,626.185,330.338Z"
                        transform="translate(-591.866 -328.555)"
                    ></path>
                </g>
                <g id="Group_11023" data-name="Group 11023" transform="translate(75.453 11.007)">
                    <path
                        id="Path_10697"
                        data-name="Path 10697"
                        d="M818.107,264.152A10.594,10.594,0,1,1,828.7,253.557,10.606,10.606,0,0,1,818.107,264.152Zm0-19.406a8.812,8.812,0,1,0,8.812,8.812A8.822,8.822,0,0,0,818.107,244.746Z"
                        transform="translate(-807.512 -242.963)"
                    ></path>
                </g>
                <g id="Group_11024" data-name="Group 11024" transform="translate(73.995 33.43)">
                    <path
                        id="Path_10698"
                        data-name="Path 10698"
                        d="M833.042,354.5H819.295a.891.891,0,1,1,0-1.783h13.747a2.755,2.755,0,0,0,2.644-3.524A22.759,22.759,0,0,0,803.1,335.484a.891.891,0,1,1-.843-1.571,24.569,24.569,0,0,1,11.582-2.9,24.3,24.3,0,0,1,14.857,5.011,24.71,24.71,0,0,1,8.7,12.672,4.538,4.538,0,0,1-4.356,5.8Z"
                        transform="translate(-801.788 -331.011)"
                    ></path>
                </g>
                <g id="Group_11027" data-name="Group 11027" transform="translate(0 11.007)">
                    <g id="Group_11025" data-name="Group 11025" transform="translate(13.146 0)">
                        <path
                            id="Path_10699"
                            data-name="Path 10699"
                            d="M573.447,264.152a10.594,10.594,0,1,1,10.595-10.594A10.606,10.606,0,0,1,573.447,264.152Zm0-19.406a8.812,8.812,0,1,0,8.812,8.812A8.822,8.822,0,0,0,573.447,244.746Z"
                            transform="translate(-562.852 -242.963)"
                        ></path>
                    </g>
                    <g id="Group_11026" data-name="Group 11026" transform="translate(0 22.423)">
                        <path
                            id="Path_10700"
                            data-name="Path 10700"
                            d="M529.516,354.5H515.768a4.537,4.537,0,0,1-4.356-5.8,24.707,24.707,0,0,1,8.7-12.672,24.3,24.3,0,0,1,14.857-5.011,24.568,24.568,0,0,1,11.582,2.9.891.891,0,1,1-.843,1.571,22.759,22.759,0,0,0-32.585,13.707,2.755,2.755,0,0,0,2.644,3.524h13.747a.891.891,0,1,1,0,1.783Z"
                            transform="translate(-511.229 -331.011)"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Friendly;
