import { API_BASE_URL } from "../config";
import { setDefaultOptions } from "../utils/common";
import { toFormData } from "../utils/toformdata";

const axios = require("axios");

const mailRequests = {};

mailRequests.sendContactUsMail = async ({ firstName, lastName, email, message }) => {
    const url = `${API_BASE_URL}/mail/SendReachoutMail`;

    try {
        await axios.post(url, { firstName, lastName, email, message }, setDefaultOptions({ token: true }));

        return "mail sent successfully!";
    } catch (e) {
        console.error(e);

        return e;
    }
};

mailRequests.sendCustomRequestMail = async ({ name, email, message, date, file }) => {
    const url = `${API_BASE_URL}/mail/SendCustomRequestMail`;

    try {
        const response = await axios.post(
            url,
            toFormData({ name, email, message, date, file }),
            setDefaultOptions({ token: true })
        );

        return response;
    } catch (e) {
        console.error(e);

        return e;
    }
};

export default mailRequests;
