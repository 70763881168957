import React from "react";

const EyeIcon = ({ width, height }) => {
    return (
        <div>
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 56">
                <path
                    fill="currentColor"
                    d="M28.01 46.403C44.556 46.403 56 33.017 56 28.844c0-4.193-11.465-17.558-27.99-17.558C11.588 11.286 0 24.651 0 28.844c0 4.173 11.671 17.559 28.01 17.559m0-6.053c-6.445 0-11.526-5.226-11.567-11.506C16.422 22.4 21.565 17.34 28.01 17.34c6.404 0 11.547 5.06 11.547 11.505c0 6.28-5.143 11.506-11.547 11.506m0-7.354c2.293 0 4.194-1.88 4.194-4.152c0-2.293-1.9-4.172-4.194-4.172c-2.313 0-4.214 1.88-4.214 4.172c0 2.273 1.9 4.152 4.214 4.152"
                />
            </svg>
        </div>
    );
};

export default EyeIcon;
