import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
// custom hooks
import { useEditPurchaseHook } from "../../context/editPurchaseContext";

// show this button when is in edit purchase page
const EditCheckboxButton = ({ musicOrQR }) => {
    const { pathname } = useLocation();
    const { enableQR, enableSongs, songsEnabled, qrEnabled, editCount, maxEditCount, isTimeValid } = useEditPurchaseHook();
    const [show, setShow] = useState(false);

    const handleEnable = () => {
        if((editCount < maxEditCount) && isTimeValid){
            if(musicOrQR === 'music') enableSongs(true);
            else enableQR(true);
        };
    }

    useEffect(() => {
        if(pathname.includes('edit-purchase')) setShow(true);
        else setShow(false);
    }, [pathname]);

    if(!show) return null;
    if(musicOrQR === 'music' && songsEnabled) return null;
    if(musicOrQR === 'qr' && qrEnabled) return null;

    return (
        <div className="enable-button-checkbox">
            <Button 
                onClick={handleEnable} 
                icon="edit"
                color="#bf5ade"
                type="primary"
                shape="circle"
                className="edit-p-btn-checkbox"
                disabled={(editCount >= maxEditCount) || !isTimeValid}
            />
        </div>
    )
}

export default EditCheckboxButton;