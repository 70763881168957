import React, { useEffect, useRef, useState } from "react";
import WrappedIfield from "./wrapped-ifield";
import { CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import { API_BASE_URL } from "../../config";
import CouponsRequest from "../../requests/Coupons";
import { isCustomerLogin, customerEmail } from "../../utils/common";
import cartRequests from "../../requests/cart";
import Swal from "sweetalert2";
import customer from "../../requests/customer";
import { useCart } from "../../context/cartContext";
import "./checkout.css";
import DownloadModal from "../../component/Downloads/DownloadModal";

export default function AppForm({ total, products, onFormLoaded, closeModal, buyNow }) {
    const isCustomer = isCustomerLogin();
    const isCustomerEmail = customerEmail() || "";
    const cardTokenRef = useRef();
    const cvvTokenRef = useRef();
    const [emailError, setEmailError] = useState("");
    const [cardNumberLength, setCardNumberLength] = useState(0);
    const [cardNumberError, setCardNumberError] = useState("");
    const [cvvLength, setCvvLength] = useState(0);
    const [cvvError, setCvvError] = useState("");
    const [expiryDateError, setExpiryDateError] = useState("");
    const [issuer, setIssuer] = useState("");
    const [amount, setAmount] = useState(2.36);
    const [firstName, setFirstName] = useState("John");
    const [lastName, setLastName] = useState("Doe");
    const [address, setAddress] = useState("123 Somewhere");
    const [city, setCity] = useState("Anywhere");
    const [addressState, setAddressState] = useState("NY");
    const [zip, setZip] = useState("98765");
    const [mobile, setMobile] = useState("1234567890");
    const [email, setEmail] = useState(isCustomerEmail);
    const [expiryDate, setExpiryDate] = useState("");
    const [gatewayResponse, setGatewayResponse] = useState("");
    const [password, setPassword] = useState("");
    const [emailExists, setEmailExists] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const { voidCart, cartQty } = useCart();
    const [downloadModal, setDownloadModal] = useState(false);
    const [couponcode, setCouponcode] = useState("");
    const [calculatedTotal, setCalculatedTotal] = useState(total);
    const [tokenAppliedtext, setTokenAppliedtext] = useState("");
    const [loading, setLoading] = useState(false);
    const [invalidCoupon, setInvalidCoupon] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [progressMessage, setProgressMessage] = useState("Processing payment...");
    const [orderId, setOrderId] = useState(null);
    const [completed, setCompleted] = useState({ status: false, message: "" });
    // Toggle function to open/close modal
    const cardRef = useRef();
    const cvvRef = useRef();

    const [showCouponInput, setShowCouponInput] = useState(false);

    const hasError = emailError || cardNumberError || cvvError || expiryDateError;
    const hasEmptyField = !cardNumberLength || !cvvLength || !expiryDate;
    const placeOrderButtonIsDisabled = loading || hasError || hasEmptyField;
    useEffect(() => {
        if (orderId) {
            const interval = setInterval(async () => {
                try {
                    const response = await fetch(`${API_BASE_URL}/cart/order-status/${orderId}`);
                    const data = await response.json();

                    console.log(data.status.status, "data");

                    if (data.status.status === "completed") {
                        clearInterval(interval);
                        setProgressMessage("Order completed! Download files now.");
                        setDownloadModal(true);
                        setCompleted({ status: true, message: "Order completed! Download files now." });
                    } else if (data.status.status === "error") {
                        clearInterval(interval);
                        setProgressMessage("An error occurred. Please contact support.");
                        setCompleted({ status: false, message: "An error occurred. Please contact support." });
                    } else {
                        setProgressMessage(`${data.status.status.replace("_", " ")}`);
                        setCompleted({ status: false, message: `${data.status.status.replace("_", " ")}` });
                    }
                } catch (error) {
                    console.error("Error checking order status:", error);
                }
            }, 5000);

            return () => clearInterval(interval); // Cleanup polling on unmount
        }
    }, [orderId]);
    useEffect(() => {
        const iframe = document.querySelector("iframe.ifields");
        if (iframe) {
            iframe.onload = () => {
                if (onFormLoaded) {
                    onFormLoaded();
                }
            };
        } else {
            if (onFormLoaded) {
                onFormLoaded();
            }
        }
    }, [onFormLoaded]);

    const checkEmailInDatabase = async (email) => {
        try {
            const response = await customer.checkEmail(email);

            console.log(response, "data");

            if (response.exists) {
                setEmailExists(true);
                setUserInfo(response.user);
                setEmail(response.user.email);
            } else {
                setEmailExists(false);
                setUserInfo(null);
            }
        } catch (error) {
            console.error("Error checking email:", error);
        }
    };

    const handleEmailChange = async (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailValue) {
            setEmailError("Email is required");
        } else if (!emailRegex.test(emailValue)) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError("");
            await checkEmailInDatabase(emailValue);
        }
    };

    const handleCouponCode = async () => {
        const couponInput = document.getElementById("couponCodeInput");
        const couponCode = couponInput ? couponInput.value.trim() : "";

        if (!couponCode) {
            setCalculatedTotal(total);
            // setTokenAppliedtext(`Please enter a coupon code.`);
            setInvalidCoupon(false);
            setTokenAppliedtext("");
            return;
        }

        try {
            const Couponsdata = await CouponsRequest.getCouponsbycode(couponCode);

            if (Couponsdata) {
                const discount = Couponsdata.discount;
                const discountedTotal = (total * (1 - discount / 100)).toFixed(2);
                setCalculatedTotal(discountedTotal);
                setCouponcode(Couponsdata);
                setTokenAppliedtext(`Coupon applied: ${Couponsdata.discount}% off`);
                setInvalidCoupon(false);
            } else {
                setTokenAppliedtext(`Invalid Coupon code`);
                setCalculatedTotal(total);
                setInvalidCoupon(true);
            }
        } catch (error) {
            console.error("Error fetching coupon:", error);
            setTokenAppliedtext("Error applying coupon");
            setInvalidCoupon(true);
        }
    };

    const handleCardToken = (data) => {
        cardTokenRef.current = data.xToken;
    };

    const handleCvvValidationUpdate = (validationData) => {
        setCvvLength(validationData.length);
        if (validationData.length < 3 || validationData.length > 4) {
            setCvvError("Please enter a valid CVV number");
        } else {
            setCvvError("");
        }
    };

    const handleCardNumberValidationUpdate = (validationData) => {
        setCardNumberLength(validationData.length);
        if (validationData.length !== 19) {
            setCardNumberError("Please enter a valid card number");
        } else {
            setCardNumberError("");
        }
    };

    const handleCvvToken = (data) => {
        cvvTokenRef.current = data.xToken;
    };

    const handleExpiryDateChange = (e) => {
        let input = e.target.value.replace(/\D/g, "");
        if (input.length > 2) {
            input = input.slice(0, 2) + "/" + input.slice(2);
        }
        setExpiryDate(input);

        if (input.length !== 5) {
            setExpiryDateError("Please enter a valid expiry date");
        } else {
            const [month, year] = input.split("/");

            if (parseInt(month) > 12) {
                setExpiryDateError("Invalid month");
            } else {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear() % 100;
                const currentMonth = currentDate.getMonth() + 1;

                if (
                    parseInt(year) < currentYear ||
                    (parseInt(year) === currentYear && parseInt(month) < currentMonth)
                ) {
                    setExpiryDateError("Expiry date cannot be in the past");
                } else {
                    setExpiryDateError("");
                }
            }
        }
    };

    const submitToGateway = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await Promise.all([cardRef.current.getToken(), cvvRef.current.getToken()]);
            const tokenWaiter = new Promise((resolve, reject) => {
                let attempts = 0;
                const interval = setInterval(() => {
                    if (cardTokenRef.current && cvvTokenRef.current) {
                        clearInterval(interval);
                        resolve();
                    } else if (attempts > 60) {
                        clearInterval(interval);
                        if (!cardTokenRef.current) {
                            reject(new Error("Card Number is required"));
                            setLoading(false);
                        } else {
                            reject(new Error("CVV is required"));
                            setLoading(false);
                        }
                    }
                    attempts++;
                    console.log("🚀 ~ interval ~ attempts:", attempts);
                }, 100);
            });

            await tokenWaiter;

            if (!cardTokenRef.current) {
                setLoading(false);
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Card number is required",
                    timer: 2000,
                    showConfirmButton: false,
                });
                throw new Error("Failed to retrieve card or CVV token");
            }

            if (!cvvTokenRef.current) {
                setLoading(false);
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "CVV is required",
                    timer: 2000,
                    showConfirmButton: false,
                });
                throw new Error("Failed to retrieve card or CVV token");
            }

            Swal.fire({
                title: "Processing Payment...",
                html: progressMessage,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            let customerData = {
                email: email,
                password: password,
                emailExists: emailExists,
                customerId: userInfo ? userInfo._id : null,
                guestId: localStorage.getItem("guest_user_id"),
                buyNow: !!buyNow,
            };
            if (isCustomer) {
                let customerId = isCustomer;
                let existingCustomer = await customer.getCustomer({ customerId });

                if (existingCustomer) {
                    customerData = {
                        email: existingCustomer.email,
                        emailExists: true,
                        customerId: existingCustomer._id,
                        guestId: null,
                        buyNow: !!buyNow,
                    };
                }
            }

            let productsdata = null;
            const customerId = isCustomerLogin(); //

            // if (buyNow) {
            //     alert("buy now");
            //     productsdata = localStorage.getItem("buyNowItem");
            // } else {
            if (customerId) {
                productsdata = await cartRequests.getFromCart(customerId);
            } else {
                productsdata = await cartRequests.getFromCartGuest(localStorage.getItem("guest_user_id"));
            }
            // }

            let totalAmount = products.reduce((acc, item) => acc + item.price * item.quantity, 0);
            let netAmount = totalAmount;

            if (couponcode) {
                console.log("couponcode", couponcode);
                const discountPercentage = couponcode.discount;
                if (discountPercentage >= 0 && discountPercentage <= 100) {
                    totalAmount = (totalAmount * (1 - discountPercentage / 100)).toFixed(2);
                }
            } else {
                totalAmount = totalAmount.toFixed(2);
            }

            if (totalAmount <= 0) {
                setLoading(false);
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Please select a product",
                    timer: 2000,
                    showConfirmButton: false,
                });
                throw new Error("Please select a product");
            }
            // return;

            let request = {
                xSoftwareName: "Test-React-iFields",
                xSoftwareVersion: "1.0",
                xVersion: "5.0.0",
                xCommand: "cc:sale",
                xAmount: totalAmount,
                xCardnum: cardTokenRef.current,
                xBillFirstName: firstName,
                xBillLastName: lastName,
                xBillStreet: address,
                xBillCity: city,
                xBillZip: zip,
                xBillState: addressState,
                xBillMobile: mobile,
                xEmail: email,
                xExp: expiryDate.replace("/", ""),
                xCvv: cvvTokenRef.current,
                xAllowDuplicate: "TRUE",
            };

            const response = await fetch(`${API_BASE_URL}/cart/process-payment`, {
                method: "POST",
                body: JSON.stringify({
                    card: request,
                    customer: customerData,
                    products: products,
                    canvas: productsdata.data[0],
                    coupondata: couponcode || null,
                    netAmount: netAmount,
                }),
                headers: { "Content-Type": "application/json" },
            });

            const responseBody = await response.json();
            // console.log(responseBody.payments.orderId, "responseBody.payments.orderId");

            if (responseBody.success) {
                setGatewayResponse(responseBody.data);

                setOrderId(responseBody.payments.orderId);
                Swal.fire({
                    title: "Payment Successful",
                    icon: "success",
                    text: responseBody.payments.message,
                    timer: 2000,
                    showConfirmButton: false,
                });
                if (!buyNow) {
                    voidCart();
                }
                // removeFromCart({ _id: id });
                // console
                localStorage.setItem("customerId", JSON.stringify(responseBody.payments.customerId));
                setDownloadModal(true);
                // await purchasesRequests.initEditCount(wooCartItemKey, orderId);
                // Clear cart
                // await cartRequests.clearCart(customerId);
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Payment Error",
                    icon: "error",
                    text: responseBody.data[0].xError,
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            setLoading(false);

            Swal.close();
            Swal.fire({
                title: "Error",
                icon: "error",
                text: error.message,
                timer: 2000,
                showConfirmButton: false,
            });
            setGatewayResponse({ message: error.message });
        }
    };

    useEffect(() => {
        if (gatewayResponse && gatewayResponse.xError !== undefined) {
            if (gatewayResponse.xError === "") {
                Swal.fire({
                    title: "Payment Successfully",
                    icon: "success",
                    text: progressMessage,
                    timer: 2000,
                    showConfirmButton: false,
                    showCloseButton: false,
                });
                if (completed) {
                    Swal.close();
                }
            } else {
                Swal.fire({
                    title: "Payment Error",
                    icon: "error",
                    text: gatewayResponse.xError,
                    timer: 2000,
                    showConfirmButton: false,
                    showCloseButton: false,
                });
            }
        }
    }, [gatewayResponse]);

    const handleCloseDownloadModal = () => {
        setDownloadModal(false);
        closeModal();
    };
    return (
        <div className="checkout-container">
            <div className="main cart-payment-container">
                <form onSubmit={submitToGateway}>
                    <div className="columns">
                        <div className="column">
                            <section className="box card-box">
                                <div className="field coupon-code-form-group mb-3" style={{ position: 'relative' }}>
                                    <p 
                                        className={`label coupon-toggle mb-0 ${showCouponInput ? 'hide' : ''}`}
                                        onClick={() => setShowCouponInput(!showCouponInput)}
                                    >
                                        Have a coupon? Click here
                                    </p>
                                    <div className={`coupon-input-container ${showCouponInput ? 'show' : ''}`}>
                                        <div >
                                            <div className="input-group">
                                                <input
                                                    className="input form-control"
                                                    type="text"
                                                    id="couponCodeInput"
                                                    placeholder="Coupon Code"
                                                    onBlur={handleCouponCode}
                                                    style={{
                                                        boxShadow: "rgba(10, 10, 10, 0.1) 0px 1px 2px inset",
                                                        backgroundColor: "white",
                                                        border: invalidCoupon
                                                            ? "2px solid rgba(var(--bs-danger-rgb))"
                                                            : "2px solid rgb(219, 219, 219)",
                                                        borderRadius: "14px 0 0 14px",
                                                        borderRight: "none !important",
                                                        color: "rgb(54, 54, 54)",
                                                        fontSize: "17px",
                                                        lineHeight: "1.5",
                                                        padding: "calc(0.375em - 1px) calc(0.625em - 1px)",
                                                        outline: "none",
                                                        height: "60px",
                                                    }}
                                                />
                                                <div className="p-2 coupon-code-apply-btn-container" style={{
                                                        border: invalidCoupon
                                                        ? "2px solid rgba(var(--bs-danger-rgb))"
                                                        : "2px solid rgb(219, 219, 219)",
                                                        borderLeft: "none",
                                                    }}>
                                                    <button
                                                        onClick={handleCouponCode}
                                                        type="button"
                                                        className="input-group-text coupon-code-apply-btn"
                                                        id="basic-addon2"
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>

                                            {!invalidCoupon && tokenAppliedtext && (
                                                <p className="coupon-code-applied pt-1">{tokenAppliedtext}</p>
                                            )}
                                            {invalidCoupon && <p className="text-danger mb-0 pt-1">Invalid coupon code</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="field d-none">
                                    <label className="label">Amount</label>
                                    <input
                                        className="input"
                                        type="text"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                                {!isCustomer && (
                                    <>
                                        <div style={{ marginBottom: "14px" }}>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={email}
                                                placeholder="Emain Address"
                                                onChange={handleEmailChange}
                                                required
                                                style={{
                                                    width: "98%",
                                                    boxShadow: "none",
                                                    fontFamily: "Nexa !important",
                                                    backgroundColor: "white",
                                                    borderRadius: "14px",
                                                    color: "#4b4b4b",
                                                    height: "3em",
                                                    fontSize: "16px",
                                                    lineHeight: "1.5",
                                                    padding: "calc(0.375em - 1px) calc(0.625em - 1px)",
                                                    outline: "none",
                                                    height: "60px",
                                                    border: emailError
                                                        ? "2px solid rgba(var(--bs-danger-rgb)) !important"
                                                        : "2px solid #d8d7d7d5",
                                                    borderRadius: "15px",
                                                    padding: "25px 0px 25px 20px",
                                                    fontWeight: "600",
                                                }}
                                            />
                                            {emailError && <p className="text-danger mb-0">{emailError}</p>}
                                        </div>
                                    </>
                                )}

                                <div className="field" style={{ marginBottom: "8px"}}>
                                    <WrappedIfield
                                        ifieldType={CARD_TYPE}
                                        onIssuer={setIssuer}
                                        onToken={handleCardToken}
                                        ref={cardRef}
                                        onValidationUpdate={handleCardNumberValidationUpdate}
                                        hasError={!!cardNumberError}
                                        placeholder="Card Number"
                                        style={{ height: "60px" }}
                                    />
                                    {cardNumberError && <p className="text-danger mb-0">{cardNumberError}</p>}
                                </div>
                                <div className="d-flex expiry_container flex-wrap">
                                    <div className="field w-50 mb-xs-0 mb-3">
                                        <div className="pr-xs-1">
                                            <input
                                                className="input cardExpiry"
                                                type="text"
                                                value={expiryDate}
                                                onChange={handleExpiryDateChange}
                                                onFocus={handleExpiryDateChange}
                                                maxLength={5}
                                                placeholder="Expiration Date"
                                                required
                                                style={{
                                                    width: "100%",
                                                    boxShadow: "rgba(10, 10, 10, 0.1) 0px 1px 2px inset",
                                                    backgroundColor: "white",
                                                    border: expiryDateError
                                                        ? "2px solid rgba(var(--bs-danger-rgb)) !important"
                                                        : "2px solid #d8d7d7d5",
                                                    borderRadius: "14px",
                                                    color: "rgb(54, 54, 54)",
                                                    height: "3em",
                                                    fontSize: "17px",
                                                    lineHeight: "1.5",
                                                    padding: "calc(0.375em - 1px) calc(0.625em - 1px)",
                                                    outline: "none",
                                                    height: "60px",
                                                }}
                                            />
                                            {expiryDateError && (
                                                <p className="text-danger mb-0 pt-2">{expiryDateError}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="field w-50">
                                        <div className="pl-xs-1">
                                            <WrappedIfield
                                                ifieldType={CVV_TYPE}
                                                onToken={handleCvvToken}
                                                ref={cvvRef}
                                                placeholder="CVV"
                                                style={{ width: "100%", height: "60px" }}
                                                onValidationUpdate={handleCvvValidationUpdate}
                                                hasError={!!cvvError}
                                            />
                                            {cvvError && <p className="text-danger mb-0">{cvvError}</p>}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="place-order-container">
                        <button
                            className="place-order-btn"
                            type="submit"
                            disabled={placeOrderButtonIsDisabled}
                            style={placeOrderButtonIsDisabled ? { opacity: 0.4, cursor: "not-allowed" } : {}}
                        >
                            {loading ? "Loading..." : ` Place Order (\$${calculatedTotal})`}
                        </button>
                    </div>
                </form>
            </div>

            <DownloadModal
                isOpen={downloadModal}
                status={completed}
                closeModalDownload={handleCloseDownloadModal}
                singleOrderId={orderId}
            />
        </div>
    );
}
