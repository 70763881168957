import React from "react";

const WeddingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 80.029 81.723">
            <g id="Component_25_1" data-name="Component 25 – 1" transform="translate(1.864 1.75)">
                <path
                    id="Path_10467"
                    data-name="Path 10467"
                    d="M514.213,776.357h0a13.836,13.836,0,0,1-12.5-15.054l3.648-24.794,24.878,2.306-.972,25.042A13.836,13.836,0,0,1,514.213,776.357Z"
                    transform="translate(-501.653 -729.294)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.5"
                />
                <line
                    id="Line_15"
                    data-name="Line 15"
                    x1="2.79"
                    y2="30.104"
                    transform="translate(9.769 47.063)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.5"
                />
                <line
                    id="Line_16"
                    data-name="Line 16"
                    x1="19.46"
                    y1="1.804"
                    transform="translate(0.04 76.266)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.5"
                />
                <path
                    id="Path_10466"
                    data-name="Path 10466"
                    d="M582.9,763.845h0a13.836,13.836,0,0,1-17.95-7.788l-7.941-23.77,23.238-9.176L590.686,745.9A13.836,13.836,0,0,1,582.9,763.845Z"
                    transform="translate(-527.197 -723.112)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.5"
                />
                <line
                    id="Line_13"
                    data-name="Line 13"
                    x2="11.103"
                    y2="28.121"
                    transform="translate(55.701 40.733)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.5"
                />
                <line
                    id="Line_14"
                    data-name="Line 14"
                    x1="18.178"
                    y2="7.177"
                    transform="translate(57.716 65.265)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.5"
                />
            </g>
        </svg>
    );
};

export default WeddingIcon;
