import React from "react";

const Price = ({width,height}) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}viewBox="0 0 104.36 95.46">
                <g id="Group_11043" data-name="Group 11043" transform="translate(47.828 -9.266) rotate(30)">
                    <g id="Group_11039" data-name="Group 11039" transform="translate(0 12.711)">
                        <path
                            id="Path_10710"
                            data-name="Path 10710"
                            d="M31.535,82.944h-19.3A12.25,12.25,0,0,1,0,70.708V21.879a12.157,12.157,0,0,1,3.584-8.652l9.649-9.649a12.25,12.25,0,0,1,17.3,0l9.649,9.649a12.156,12.156,0,0,1,3.584,8.652V70.708A12.25,12.25,0,0,1,31.535,82.944ZM21.885,1.552a10.65,10.65,0,0,0-7.554,3.124L4.682,14.325a10.613,10.613,0,0,0-3.129,7.554V70.708A10.7,10.7,0,0,0,12.236,81.392h19.3A10.7,10.7,0,0,0,42.218,70.708V21.879a10.612,10.612,0,0,0-3.129-7.554L29.439,4.676A10.649,10.649,0,0,0,21.885,1.552Z"
                        ></path>
                    </g>
                    <g id="Group_11040" data-name="Group 11040" transform="translate(12.905 44.727)">
                        <path
                            id="Path_10711"
                            data-name="Path 10711"
                            d="M16.427,8.446c-1.2-2.228-4.157-3.159-6.618-3.192-2.76,0-6.85.931-6.784,4.29.033,2.76,3.225,3.458,6.85,4.09,4.589.8,9.378,1.8,9.378,6.817-.033,4.423-4.456,6.318-8.347,6.518v3.259H8.779V27C5.287,26.836,1.6,25.439,0,22.18l2-1.031c1.33,2.693,4.922,3.658,7.782,3.658,2.893,0,7.083-.8,7.083-4.389.033-3.159-3.658-3.924-7.283-4.589C5.188,15.1.831,14.233.732,9.61c-.1-4.356,4.29-6.119,8.047-6.385V0h2.161V3.226c2.96.233,5.919,1.363,7.416,4.19Z"
                        ></path>
                    </g>
                    <g id="Group_11041" data-name="Group 11041" transform="translate(16.217 21.253)">
                        <path
                            id="Path_10712"
                            data-name="Path 10712"
                            d="M5.668,11.336a5.668,5.668,0,1,1,5.668-5.668A5.675,5.675,0,0,1,5.668,11.336Zm0-9.784A4.116,4.116,0,1,0,9.784,5.668,4.121,4.121,0,0,0,5.668,1.553Z"
                        ></path>
                    </g>
                    <g id="Group_11042" data-name="Group 11042" transform="translate(18.531 0)">
                        <path
                            id="Path_10713"
                            data-name="Path 10713"
                            d="M4.121,27.817a.774.774,0,0,1-.573-.252A14.067,14.067,0,0,1,1.233,12.481,14.074,14.074,0,0,1,14.275,4.557c3.865.165,7.349,1.906,10.585,3.674l.821.451c2.852,1.569,5.8,3.191,8.963,3.778,2.842.528,6.96.124,9.208-2.753a6.913,6.913,0,0,0-.206-8.424A.776.776,0,1,1,44.821.269a8.419,8.419,0,0,1,.255,10.4c-2.2,2.818-6.408,4.122-10.715,3.323-3.407-.633-6.468-2.316-9.428-3.945l-.818-.449c-3.076-1.681-6.375-3.335-9.907-3.486A12.47,12.47,0,0,0,2.64,13.137a12.478,12.478,0,0,0,2.054,13.38.776.776,0,0,1-.573,1.3Z"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Price;
