import React, { useEffect, useState } from 'react';
import AutoCompleteJS from '@tarekraafat/autocomplete.js';
import HebrewInput from './HebrewInput';
// requests
import fieldRequests from '../../requests/fields';

import '@tarekraafat/autocomplete.js/dist/css/autoComplete.css';
import './autocompleteInput.css';

const AutocompleteInput = ({ onChange, value, name, autocompleteLabel, associatedValueDisplayed, isUpperCase = false }) => {
    const [autoComplete, setAutoComplete] = useState(null);
    const [lastSuggestionSelected, setLastSuggestionSelected] = useState('');
    const [suggestionWasSelected, setSuggestionWasSelected] = useState(false);

    const onChangeHandler = async (ev) => {
        setSuggestionWasSelected(false);
        await onChange(ev);
    }

    // init AutoCompleteJS
    useEffect(() => {
        const _autoComplete = new AutoCompleteJS({
            selector: `#input-keyboard-${name}`,
            data: {
                src: [],
                keys: ['value']
            },
            threshold: 3
        });

        setAutoComplete(_autoComplete);
    }, [name]);


    // event handlers
    useEffect(() => {
        if(!autoComplete) return;

        const onSelectionHandler = suggestionSelected => {
            onChange({
                target: { name, value: suggestionSelected.value },
                associatedValue: suggestionSelected.associatedValue
            });
            setLastSuggestionSelected(suggestionSelected.value);
            setSuggestionWasSelected(true);
        }

        const onClickOutsideHandler = ev => {
            let wrapper = document.getElementById('inputs-list-wrapper');
            if(wrapper && !wrapper.contains(ev.target)){
                autoComplete.close();
            }
        }

        document.querySelector(`#input-keyboard-${name}`).addEventListener("selection", ev => {
                onSelectionHandler(ev.detail.selection.value);
        });
        document.body.addEventListener('click', onClickOutsideHandler);

        return () => {
            document.querySelector(`#input-keyboard-${name}`)?.removeEventListener("selection", onSelectionHandler);
            document.body.removeEventListener('click', onClickOutsideHandler);
        }
    }, [name, autoComplete]);


    // show suggestions when input value changes
    useEffect(() => {
        if(autoComplete){
            if(value === lastSuggestionSelected || value === ''){
                autoComplete.close();
                return;
            }
            const inputsWrapper = document.getElementById('inputs-list-wrapper');

            // input list wrapper height
            const inputsWrapperHeight = Number(window.getComputedStyle(inputsWrapper).height.split('px')[0]);
            // inputsWrapper offset
            const inputsWrapperY = inputsWrapper.getBoundingClientRect().y;
            // input offset
            const inputY = document.getElementById(`input-keyboard-${name}`).getBoundingClientRect().y;
            // set bottom position for suggestions list (already position: absolute)
            autoComplete.list.style.bottom = (inputsWrapperHeight - (inputY - inputsWrapperY)) - inputsWrapper.scrollTop + 3 + 'px';

            // get suggestions
            if(value){
                fieldRequests.getSuggestionsWithAssociatedValue(autocompleteLabel, value)
                    .then( suggestionsArrObjs => {
                        autoComplete.data.src = suggestionsArrObjs;
                        autoComplete.start(value);
                    })
            }else{
                autoComplete.close();
            }
        }
    }, [value, name])

    return (
        <div className="autocomplete-input-wrapper">
            <input
                type="text"
                id={`input-keyboard-${name}`}
                // onChange={onChangeHandler}
                defaultValue={value}
                className="autocomplete-input-elmt"
            />

            <HebrewInput
                onChange={onChangeHandler}
                name={name}
                value={value}
                suggestionWasSelected={suggestionWasSelected}
                lastSuggestionSelected={lastSuggestionSelected}
                associatedValueDisplayed={associatedValueDisplayed}   
                isUpperCase={isUpperCase}           
            />
        </div>
    );
}                                                                          

export default AutocompleteInput;