import React from "react";

const KidDushIcon = () => {
    return (
        <div>
            <svg
                id="Component_20_1"
                data-name="Component 20 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 78.928 83.09"
            >
                <path
                    id="Path_10432"
                    data-name="Path 10432"
                    d="M537.208,228.8a1.743,1.743,0,0,1-.435-3.43,28.186,28.186,0,0,0,19.738-18.815,1.743,1.743,0,0,1,1.517-1.221,7.194,7.194,0,0,0,5.422-2.9,8.619,8.619,0,0,0,1.539-4.948,7.973,7.973,0,0,0-.4-2.531c-.68-2.036-2.321-2.878-4.882-2.5a1.742,1.742,0,0,1-1.995-1.639,27.842,27.842,0,0,0-8.726-18.944,1.743,1.743,0,0,1,2.4-2.531,31.306,31.306,0,0,1,9.676,19.548,7.011,7.011,0,0,1,6.838,4.962,11.462,11.462,0,0,1,.574,3.634,12.01,12.01,0,0,1-2.2,6.987,10.543,10.543,0,0,1-6.786,4.179,31.713,31.713,0,0,1-21.843,20.092,1.753,1.753,0,0,1-.438.056m-17.356-.269a1.727,1.727,0,0,1-.5-.072,31.462,31.462,0,0,1-20.779-19.665,10.544,10.544,0,0,1-6.824-4.178,12.018,12.018,0,0,1-2.209-7,11.459,11.459,0,0,1,.575-3.63,7.007,7.007,0,0,1,6.824-4.964,31.321,31.321,0,0,1,9.5-19.506,1.742,1.742,0,1,1,2.416,2.511A27.857,27.857,0,0,0,500.3,190.94a1.742,1.742,0,0,1-1.994,1.647c-2.561-.377-4.2.465-4.877,2.5a7.965,7.965,0,0,0-.4,2.528,8.628,8.628,0,0,0,1.549,4.959,7.188,7.188,0,0,0,5.448,2.9,1.743,1.743,0,0,1,1.521,1.214,27.976,27.976,0,0,0,18.8,18.434,1.743,1.743,0,0,1-.493,3.414"
                    transform="translate(-489.545 -150.036)"
                />
                <path
                    id="Path_10433"
                    data-name="Path 10433"
                    d="M536.326,207.638a4.07,4.07,0,1,1-4.069-4.07,4.069,4.069,0,0,1,4.069,4.07"
                    transform="translate(-504.961 -163.881)"
                />
                <path
                    id="Path_10434"
                    data-name="Path 10434"
                    d="M577.67,207.638a4.069,4.069,0,1,1-4.069-4.07,4.07,4.07,0,0,1,4.069,4.07"
                    transform="translate(-521.455 -163.881)"
                />
                <path
                    id="Path_10435"
                    data-name="Path 10435"
                    d="M539.93,240.109a1.738,1.738,0,0,1-1.336-.623A8.469,8.469,0,0,1,536.536,234c0-5.229,5.033-9.483,11.219-9.483s11.219,4.254,11.219,9.483a8.472,8.472,0,0,1-2.038,5.461,1.742,1.742,0,0,1-2.678-2.23A5.03,5.03,0,0,0,555.49,234c0-3.308-3.469-6-7.734-6s-7.734,2.69-7.734,6a5.032,5.032,0,0,0,1.243,3.245,1.742,1.742,0,0,1-1.335,2.862"
                    transform="translate(-508.291 -172.239)"
                />
                <path
                    id="Path_10436"
                    data-name="Path 10436"
                    d="M547.755,260.467a11.393,11.393,0,0,1-11.219-11.535c0-6.339,9.414-10.877,10.487-11.374a1.748,1.748,0,0,1,1.463,0c1.073.5,10.488,5.035,10.488,11.374a11.393,11.393,0,0,1-11.219,11.535m0-19.383c-2.6,1.361-7.734,4.689-7.734,7.848a7.74,7.74,0,1,0,15.469,0c0-3.159-5.129-6.486-7.734-7.848"
                    transform="translate(-508.291 -177.376)"
                />
                <path
                    id="Path_10437"
                    data-name="Path 10437"
                    d="M550.4,161.672a8.071,8.071,0,0,1-8.15-7.971v-.934a8.151,8.151,0,0,1,16.3,0v.934a8.071,8.071,0,0,1-8.149,7.971m0-13.392a4.583,4.583,0,0,0-4.665,4.486v.934a4.668,4.668,0,0,0,9.329,0v-.934a4.583,4.583,0,0,0-4.664-4.486"
                    transform="translate(-510.569 -140.434)"
                />
                <path
                    id="Path_10438"
                    data-name="Path 10438"
                    d="M528.981,163.138h-3.844c-5.93,0-10.072-5.263-10.072-12.8s4.141-12.8,10.072-12.8h3.844c2.846,0,5.633,2.143,8.287,6.37a1.743,1.743,0,0,1-.471,2.35,4.393,4.393,0,0,0-1.909,3.612v.934a4.393,4.393,0,0,0,1.909,3.612,1.743,1.743,0,0,1,.471,2.35c-2.654,4.227-5.441,6.37-8.287,6.37m-3.844-22.113c-4.55,0-6.587,4.678-6.587,9.314s2.036,9.314,6.587,9.314h3.844c1.285,0,2.9-1.266,4.518-3.52a7.884,7.884,0,0,1-2.1-5.327v-.934a7.885,7.885,0,0,1,2.1-5.327c-1.623-2.255-3.233-3.52-4.518-3.52Z"
                    transform="translate(-499.727 -137.54)"
                />
                <path
                    id="Path_10439"
                    data-name="Path 10439"
                    d="M571.56,163.139h-3.844c-2.846,0-5.633-2.143-8.287-6.37a1.743,1.743,0,0,1,.47-2.349,4.4,4.4,0,0,0,1.909-3.613v-.933a4.395,4.395,0,0,0-1.909-3.613,1.743,1.743,0,0,1-.47-2.349c2.654-4.227,5.441-6.37,8.287-6.37h3.844c5.93,0,10.071,5.263,10.071,12.8s-4.141,12.8-10.071,12.8m-8.362-7.005c1.623,2.255,3.233,3.52,4.518,3.52h3.844c4.55,0,6.586-4.677,6.586-9.314s-2.037-9.314-6.586-9.314h-3.844c-1.285,0-2.9,1.266-4.518,3.521a7.89,7.89,0,0,1,2.1,5.327v.933a7.889,7.889,0,0,1-2.1,5.327"
                    transform="translate(-517.318 -137.541)"
                />
            </svg>
        </div>
    );
};

export default KidDushIcon;
