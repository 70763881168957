import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
//custom components
import EditCheckboxButton from "../Edit/editCheckboxButton";
// requests
import cartRequests from "../../requests/cart";
import orderRequests from "../../requests/orders";
// custom hooks
import { useEditPurchaseHook } from "../../context/editPurchaseContext";
// utils
import { createFabricQRImage } from "../../utils/createFabricQRImage";
import { getCanvasRelativeWidth } from "../../utils/canvas";
import { getUrlQuery } from "../../utils/url";
// mocks
// import mockupOrderData from '../../mocks/order_data_mockup';
// styles
import "react-phone-number-input/style.css";
import { isCustomerLogin } from "../../utils/common";

// get dimentions relative to original canvas editor in the admin
function getQrCodeDimensions(canvas, wpCanvasObjects = null) {
    const qrCanvasObjs = wpCanvasObjects ? wpCanvasObjects : canvas.getObjects();
    const qrCanvasObj = qrCanvasObjs.find((obj) => obj.label === "QR Code" && obj.type === "rect");

    console.log(qrCanvasObj, "qrCanvasObj");

    if (!qrCanvasObj)
        return {
            maxWidth: 100,
            top: canvas.getHeight() - 100,
            left: 0,
        };

    return {
        // width, top and left already modified when the invisible rect object was added to the canvas
        maxWidth: qrCanvasObj.width,
        top: qrCanvasObj.top,
        left: qrCanvasObj.left,
    };
}

const QrCodeInput = ({ canvas, setQRText, isEditing, setIsEditing }) => {
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const cart_item_key = query.get("cart_item_key");

    const [checked, setChecked] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [showGenerateButton, setShowGenerateButton] = useState(false);
    const [checkboxDisabled, setCheckboxDisabled] = useState(false);
    const { pathname } = useLocation();
    const editPurchaseState = useEditPurchaseHook();
    const [validationError, setValidationError] = useState("");

    const removeQrCode = (isOnLoad = false) => {
        const qrImageGroup = canvas.getObjects().find((obj) => obj.name === "qr group");
        const group = canvas.getObjects().find((obj) => obj.type === "group");
        const objToDelete = qrImageGroup || group;

        if (objToDelete) {
            canvas.remove(objToDelete);
            canvas.renderAll();
        }

        if (isOnLoad) return;
        if (cart_item_key) setIsEditing(true);
    };

    const handleCheck = (ev) => {
        const { checked } = ev.target;
        setChecked(checked);
        setShowInput(checked);
        setInputValue("");
        setQRText("");
        // remove QR Code
        if (!checked) removeQrCode();
        // enable qr in "edit purchase" page
        if (pathname.includes("edit-purchase")) {
            const { editCount, maxEditCount, qrEnabled, enableQR, isTimeValid } = editPurchaseState;
            if (qrEnabled) return;

            if (editCount < maxEditCount && isTimeValid) {
                enableQR(true);
            }
        }
    };

    const handleChange = (value) => {
        if (!isEditing) setIsEditing(true);
        setValidationError("");
        if (!value) return;

        if (isPossiblePhoneNumber(value)) {
            const fullPhone = formatPhoneNumberIntl(value);
            setInputValue(value);
            setQRText(fullPhone);
        }
    };

    const handleGenerateQR = async (onLoadText = "", wpCanvasObjects = null) => {
        const inputText = onLoadText || inputValue;
        const phoneRegex = /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/;
        if (!phoneRegex.test(inputText)) {
            setValidationError("Please enter a valid phone number");
            return;
        }

        const { maxWidth, left, top } = getQrCodeDimensions(canvas, wpCanvasObjects);

        console.log("left", top);
        const posterWidth = getCanvasRelativeWidth(canvas);

        const fabricQRImage = await createFabricQRImage({
            size: maxWidth,
            phoneNumber: inputText,
            left,
            top,
            pageName: "detail",
            posterWidth,
        });
        // remove previous qr code
        removeQrCode(!!onLoadText);
        canvas.add(fabricQRImage);
        canvas.renderAll();

        if (!onLoadText) {
            setShowInput(false);
        }

        setTimeout(() => {
            canvas.renderAll();
        }, 25);
    };

    // parse data from woocomerce and recreate qr
    const _recreateQRFromWP = (wooData) => {
        if (!wooData) return;

        const firstCartItem = wooData.data[0];
        const customizationDataString = firstCartItem.customizationData;

        const customizationData = JSON.parse(customizationDataString);
        let QRText = customizationData.QRText;

        // console.log("QRText", QRText);

        QRText = QRText.replace(/ /g, "");
        // console.log("wooData.customization_data?.canvasObjects", customizationData.canvasObjects);
        setInputValue(QRText);
        setChecked(true);
        setShowInput(true);
        setQRText(QRText);
        handleGenerateQR(QRText, customizationData?.canvasObjects).then(() => console.log("QR generated again"));
    };

    // get data from woocomerce
    useEffect(() => {
        const { order_item_id, order_id } = getUrlQuery();

        // editing order item
        if (order_item_id) {
            orderRequests
                .getOrderItem({
                    productId: productData._id,
                    orderId: order_id,
                })
                .then((orderData) => {
                    _recreateQRFromWP(orderData);
                });

            return;
        }

        // editing cart item
        if (cart_item_key) {
            const customerId = isCustomerLogin();
            cartRequests.getFromCartWithCartKey(cart_item_key).then((cartData) => {
                // const firstCartItem = wooData.data[0];
                // const customizationDataString = firstCartItem.customizationData;
                // const customizationData = JSON.parse(customizationDataString);

                if (!cartData) return;

                _recreateQRFromWP(cartData);
            });
        }
    }, []);

    useEffect(() => {
        if (pathname.includes("edit-purchase")) {
            const { editCount, maxEditCount, qrEnabled, isTimeValid } = editPurchaseState;
            if (qrEnabled) return;

            if (editCount < maxEditCount && isTimeValid) setCheckboxDisabled(false);
            else setCheckboxDisabled(true);
        } else setCheckboxDisabled(false);
    }, [pathname, editPurchaseState]);

    useEffect(() => {
        if (pathname === "/edit-purchase") {
            const { qrEnabled } = editPurchaseState;
            setShowGenerateButton(qrEnabled);
        } else setShowGenerateButton(true);
    }, [pathname, editPurchaseState]);

    return (
        <div className="w-100">
            <div className="pd-checkbox mb-2">
                <EditCheckboxButton musicOrQR="qr" />

                <div className="d-flex align-items-center">
                    <input
                        type="checkbox"
                        id="add-qr-check"
                        checked={checked}
                        onChange={handleCheck}
                        disabled={checkboxDisabled}
                        style={{
                            display:
                                !editPurchaseState.qrEnabled && pathname === "/edit-purchase" ? "none" : "inline-block",
                        }}
                    />
                    <label htmlFor="add-qr-check">Add QR code</label>
                </div>
            </div>

            {showInput && (
                <div className="qr-input-wrapper">
                    <div className="my-2">
                        <PhoneInput
                            defaultCountry="US"
                            value={inputValue}
                            id="input-qr-elmt"
                            autoComplete="off"
                            onChange={handleChange}
                            placeholder="Enter phone number"
                            disabled={!showGenerateButton}
                        />
                        {validationError && (
                            <div className="text-danger mt-1">{validationError}</div>
                        )}
                    </div>
                    {showGenerateButton && (
                        <button id="btn-qr" onClick={() => handleGenerateQR(null)}>
                            Generate qr code
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default QrCodeInput;
