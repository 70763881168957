import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./assets/css/sweetalert.css";
import "./App.css";
import "./Vendor.js";
import "font-awesome/css/font-awesome.min.css";

import HomePage from "./component/home/index";

const SignUp = lazy(() => import("./component/Account/AccountSignUp"));
const ResetPassword = lazy(() => import("./component/common/ResetPassword.jsx"));
const ShopPage = lazy(() => import("./component/shop"));
// const ProductDetail = lazy(() => import("./component/shop/product-detail"));
import ProductDetail from "./component/shop/product-detail";
const WishList = lazy(() => import("./component/WishList/WishList"));
const Downloads = lazy(() => import("./component/Downloads/downloads.jsx"));
const EditPurchase = lazy(() => import("./component/Edit/editPurchase"));
const PageNotFound = lazy(() => import("./component/Pages/PageNotFound"));
const TermsAndConditions = lazy(() => import("./component/TermsAndConditions/Terms&Conditions.jsx"));
const CheckOut = lazy(() => import("./component/Checkout/checkout"));

const Profile = lazy(() => import("./component/Account/Profile"));
const Address = lazy(() => import("./component/Account/Address"));
const OrderHistory = lazy(() => import("./component/Account/OrderHistory"));
const Account = lazy(() => import("./component/Account/AccountProfile"));
const AccountProfileedit = lazy(() => import("./component/Account/AccountProfileedit.js"));
const PrivateRoute = lazy(() => import("./component/common/PrivateRoute"));

import "../src/assets/css/global.css";
import "./hazmoona.css";

const App = () => {
    const location = useLocation();

    return (
        <GoogleOAuthProvider clientId="611268622699-28354ljs18rs5b81230qbf0l4rj4srh7.apps.googleusercontent.com">
            <div className="site-content" id="site-content">
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/account-signup" element={<SignUp />} />
                        <Route path="/login" element={<SignUp />} />
                        <Route path="/reset-password/:token" element={<ResetPassword />} />
                        <Route path="/terms-conditions" element={<TermsAndConditions />} />
                        <Route path="/categories/:categoryFriendlyName" element={<ShopPage />} />
                        <Route path="/card/:productId" element={<ProductDetail />} />
                        <Route path="/wishlist" element={<WishList />} />
                        <Route path="/checkout" element={<CheckOut />} />
                        <Route path="/download-files" element={<Downloads />} />
                        <Route path="/personalize-check" element={<ProductDetail />} />
                        <Route path="/edit-purchase" element={<EditPurchase />} />

                        {/* Private Routes */}
                        <Route
                            path="/account"
                            element={
                                <PrivateRoute>
                                    <Profile />
                                </PrivateRoute>
                            }
                        >
                            <Route path="address" element={<Address />} />
                            <Route path="orderhistory" element={<OrderHistory />} />
                            <Route index path="accountprofile" element={<Account />} />
                            <Route path="profile" element={<Account />} />
                            <Route index path="edit-profile" element={<AccountProfileedit />} />
                        </Route>

                        {/* 404 Page */}
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Suspense>
            </div>
        </GoogleOAuthProvider>
    );
};

export default App;
