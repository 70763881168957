import React from "react";

const BabyBoyIcon = () => {
    return (
        <div>
            <svg
                id="Component_19_3"
                data-name="Component 19 – 3"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="25"
                height="25"
                viewBox="0 0 75.552 77.797"
            >
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_872" data-name="Rectangle 872" width="75.552" height="77.797" />
                    </clipPath>
                </defs>
                <g id="Group_10918" data-name="Group 10918">
                    <g id="Group_10918-2" data-name="Group 10918" clip-path="url(#clip-path)">
                        <path
                            id="Path_10503"
                            data-name="Path 10503"
                            d="M53.47,57.834c-17.676,0-32.233-12.81-32.45-28.555a1.519,1.519,0,0,1,1.518-1.54H49.2a1.625,1.625,0,0,0,1.623-1.623V1.544A1.519,1.519,0,0,1,52.294.026l.062,0C52.727.012,53.1,0,53.47,0c17.9,0,32.461,12.972,32.461,28.917S71.369,57.834,53.47,57.834M24.13,30.777c1.093,13.38,13.9,24.02,29.34,24.02,16.224,0,29.424-11.609,29.424-25.879,0-14.155-12.987-25.693-29.031-25.877V26.116a4.665,4.665,0,0,1-4.66,4.66Z"
                            transform="translate(-10.379)"
                        />
                        <path
                            id="Path_10504"
                            data-name="Path 10504"
                            d="M12.04,48a1.52,1.52,0,0,1-1.5-1.27c-.35-2.112-.557-3.183-.932-5.126l-.218-1.13a3.632,3.632,0,0,0-4.248-2.877,2.6,2.6,0,0,0-2.061,3.042,1.776,1.776,0,0,0,2.079,1.408,1.054,1.054,0,0,0,.473-.332,1.519,1.519,0,0,1,2.421-1.83,2.792,2.792,0,0,1,.487,2.774,3.891,3.891,0,0,1-2.808,2.37A4.819,4.819,0,0,1,.1,41.207a5.642,5.642,0,0,1,4.471-6.6,6.671,6.671,0,0,1,7.8,5.287l.217,1.128c.368,1.9.59,3.053.947,5.2A1.52,1.52,0,0,1,12.04,48"
                            transform="translate(0 -17.029)"
                        />
                        <path
                            id="Path_10505"
                            data-name="Path 10505"
                            d="M40.154,132.943a10.63,10.63,0,1,1,10.63-10.63,10.642,10.642,0,0,1-10.63,10.63m0-18.222a7.592,7.592,0,1,0,7.592,7.592,7.6,7.6,0,0,0-7.592-7.592"
                            transform="translate(-14.579 -55.146)"
                        />
                        <path
                            id="Path_10506"
                            data-name="Path 10506"
                            d="M50.6,129.5a3.261,3.261,0,1,1-3.261-3.261A3.261,3.261,0,0,1,50.6,129.5"
                            transform="translate(-21.766 -62.334)"
                        />
                        <path
                            id="Path_10507"
                            data-name="Path 10507"
                            d="M110.489,132.943a10.63,10.63,0,1,1,10.63-10.63,10.642,10.642,0,0,1-10.63,10.63m0-18.222a7.592,7.592,0,1,0,7.592,7.592,7.6,7.6,0,0,0-7.592-7.592"
                            transform="translate(-49.307 -55.146)"
                        />
                        <path
                            id="Path_10508"
                            data-name="Path 10508"
                            d="M120.938,129.5a3.261,3.261,0,1,1-3.261-3.261,3.261,3.261,0,0,1,3.261,3.261"
                            transform="translate(-56.495 -62.334)"
                        />
                        <path
                            id="Path_10509"
                            data-name="Path 10509"
                            d="M79.087,72.36c-2.184,0-3.274-1.437-4.07-2.486-.722-.952-1.03-1.284-1.651-1.284s-.927.333-1.649,1.284c-.8,1.049-1.885,2.486-4.069,2.486s-3.274-1.437-4.069-2.486c-.722-.952-1.03-1.284-1.65-1.284s-.927.333-1.648,1.284c-.8,1.049-1.886,2.486-4.069,2.486s-3.273-1.437-4.069-2.486c-.721-.951-1.028-1.284-1.647-1.284s-.927.333-1.648,1.283c-.8,1.049-1.885,2.487-4.068,2.487s-3.273-1.437-4.069-2.487c-.721-.951-1.028-1.283-1.647-1.283s-.927.333-1.648,1.284c-.8,1.049-1.885,2.486-4.069,2.486s-3.273-1.437-4.069-2.486c-.721-.951-1.029-1.284-1.648-1.284a1.519,1.519,0,1,1,0-3.037c2.184,0,3.273,1.437,4.069,2.486.721.951,1.029,1.284,1.649,1.284s.927-.333,1.648-1.284c.8-1.049,1.885-2.486,4.069-2.486s3.273,1.437,4.068,2.486c.721.951,1.028,1.284,1.648,1.284s.927-.333,1.648-1.284c.8-1.049,1.884-2.486,4.068-2.486s3.272,1.437,4.068,2.486c.721.951,1.028,1.284,1.648,1.284s.927-.333,1.648-1.284c.8-1.049,1.886-2.486,4.069-2.486S65.2,66.99,66,68.039c.721.951,1.029,1.284,1.649,1.284s.927-.333,1.649-1.284c.8-1.049,1.885-2.486,4.069-2.486s3.275,1.437,4.071,2.486c.722.951,1.03,1.284,1.651,1.284s.929-.333,1.651-1.284c.8-1.049,1.886-2.486,4.071-2.486a1.519,1.519,0,0,1,0,3.037c-.621,0-.929.333-1.651,1.284-.8,1.049-1.886,2.486-4.07,2.486"
                            transform="translate(-12.893 -32.368)"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default BabyBoyIcon;
