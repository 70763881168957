import { API_BASE_URL } from "../config";
import ajax from "./ajax";

const purchasesRequests = {};

purchasesRequests.initEditCount = async function ({ wooCartItemKey, orderId }) {
    const url = `${API_BASE_URL}/purchases/InitEditCount`;

    const countStarted = await ajax.post(url, { wooCartItemKey, orderId });
    return countStarted;
};

purchasesRequests.updateEditCount = async function ({ wooCartItemKey, orderId, fieldsEditedCount }) {
    const url = `${API_BASE_URL}/purchases/UpdateEditCount`;

    const updated = await ajax.post(url, { wooCartItemKey, orderId, fieldsEditedCount });
    return updated;
};

purchasesRequests.getEditCount = async function ({ wooCartItemKey, orderId }) {
    const url = `${API_BASE_URL}/purchases/GetEditCount?wooCartItemKey=${wooCartItemKey}&orderId=${orderId}`;

    const count = await ajax.get(url);
    return count;
};

export default purchasesRequests;
