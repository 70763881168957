import React, { useEffect } from "react";
import "./hero.css";

const Hero = () => {
    useEffect(() => {
        document.getElementById("site-content").style.backgroundColor = "#ffffff";
    }, []);

    return (
        <div className="hero-container">
            <section className="hero">
                <div className="hero-left">
                    <h1 className="hero-hd">Invite them with the same standards as you’ll delight them.</h1>
                    <p className="hero-text">
                        Custom and template invitations designed with eye catching creativity and attention to detail.
                    </p>
                    <GroupButtons className="visible" />
                </div>

                {/* <div> */}
                <div className="hero-right">
                    <div>
                        <video className="hero-video" autoPlay={true} loop={true} muted={true} playsInline={true}>
                            <source
                                src="https://hazmoona.com/wp-content/uploads/2023/05/animation_fab_5.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <GroupButtons className="hidden" />
                </div>
                {/* </div> */}
            </section>
        </div>
    );
};

const GroupButtons = (props) => {
    const { className, ...restProps } = props;
    const combinedClassName = `hero-btns flex-sm-nowrap flex-wrap ${className || ""}`.trim();

    return (
        <div className={combinedClassName} {...restProps}>
            <a href="#chooseCategorysec" className="btn btn--contained">
                Choose my Invite
            </a>
            <a href="#custom-design" className="btn btn--outlined">
                Can I have it Custom?
            </a>
        </div>
    );
};

export default Hero;
