import { HebrewCalendar, HDate } from '@hebcal/core';

const nikudReplaceRegex = /[\u0591-\u05BD\u05BF\u05C1\u05C2\u05C4-\u05C7]/g

class ParshaCalculator {
  static determineParsha(date, prefixParsha = false) {
    let parshaName;
    const parsha = this._getParsha(date);

    if (parsha.attempt > 1 && parsha.en.toLowerCase().indexOf('bereshit') > -1) {
      parshaName = "וְזֹאת הַבְּרָכָה".replace(nikudReplaceRegex, '');
    } else {
      parshaName = parsha.he.replace(nikudReplaceRegex, '').replace('־', ' ').replace('פרשת ', '');
    }

    return `${prefixParsha ? `פרשת ` : ``}${ParshaCalculator._parshaConverter(parshaName)}`;
  }

  static _parshaConverter = (parsha) => {
    return parsha
      .replace("מצרע", "טהרה")
      .replace("קדשים", "קדושים")
      .replace("אחרי מות קדושים", "אחרי קדושים")
      .replace("בחקתי", "בחקותי")
      .replace("בהעלתך", "בהעלותך")
      .replace("קורח", "קרח");
  }

  static _getParsha(date, attempt = 1) {
    const sdate = this._getNextSaturday(date);
    const hd = new HDate(sdate);
    const events = HebrewCalendar.calendar({ sedrot: true, noHolidays: true, year: date.getFullYear() });
    const parsha = events.find(x => x.date.day == hd.day && x.date.month == hd.month && x.date.year == hd.year);

    if (!parsha) {
      return this._getParsha(new Date(sdate.setDate(date.getDate() + 7)), attempt + 1)
    }

    return { he: parsha.render("he"), attempt, en: parsha.desc }
  }

  static _getNextSaturday(date) {
    let d = new Date(date);
    let daysUntilSaturday = (6 - d.getDay()) % 7;
    d.setDate(d.getDate() + daysUntilSaturday);
    return d;
  }
}

export default ParshaCalculator;
