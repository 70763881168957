import React, { useEffect, useState } from "react";
// custom components
import Loader from "../Loader/customLoader";
import { Spinner } from "reactstrap";
// utils
import { getUrlQuery } from "../../utils/url";
import {
    initCanvas,
    createPDFAndDownloadV2,
    generateVideoAndGetURL,
    getSmallerCanvasBase64ImageForJPEG,
    // getPosterbase64Image
} from "../../utils/handleCreateFiles";
import { forceRenderUpdate } from "../../utils/canvas";
import { downloadVideo, downloadImage } from "../../utils/downloadManager";
import saveAutocompleteValues from "../../utils/saveAutoCompleteValues";
import isMobile from "../../utils/detectMobile";
// import emulateMessageDebugger from '../../utils/emulateDebugOnIframe';
// requests
import orderRequests from "../../requests/orders";
//style
import "./style/downloads.css";
// images
import imageIcon from "../../assets/images/icons/image-icon.svg";
import pdfIcon from "../../assets/images/icons/file-icon.svg";
import videoIcon from "../../assets/images/icons/videocam-icon.svg";
// import mockdata from '../../mocks/order_data_mockup';
// import mockdata from '../../mocks/mockdata';
// import mockdata from '../../mocks/mockdata2';

const Downloads = ({ productID, orderId, status }) => {
    const [orderItemData, setOrderItemData] = useState({});
    // const [pdfSizes, setPdfSizes] = useState([]);
    const [pdfTagSize, setPdfTagSize] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [videoUrlCreated, setVideoUrlCreated] = useState("");
    const [isCreatingVideo, setIsCreatingVideo] = useState(false);
    const [previewImageState, setPreviewImageState] = useState({ loading: false, imageUrl: "" });
    const [customizationData, setCustomizationData] = useState();
    const [assets, setAssets] = useState(true);

    useEffect(() => {
        if (!status.status) return;

        setDataLoading(true);

        setAssets(false);

        if (orderId) {
            orderRequests
                .getOrderItem({
                    productId: productID,
                    orderId: orderId,
                })
                .then((data) => {
                    setOrderItemData(data?.orderReqdata);
                    setCustomizationData(data?.orderReqdata?.customization_data);
                    setDataLoading(false);

                    const { QRText, canvasObjects, productFilesId, productName } = JSON.parse(
                        data.orderReqdata.customization_data
                    );

                    // init canvas with original image
                    initCanvas({
                        canvasObjects,
                        QRText,
                        product: { productFilesId, productName },
                    }).then(async (tempCanvas) => {
                        await forceRenderUpdate(tempCanvas, true, false, true);

                        const previewImgBase64 = tempCanvas.toDataURL({
                            format: "jpeg",
                        });

                        setPreviewImageState({ loading: false, imageUrl: previewImgBase64 });

                        saveAutocompleteValues(tempCanvas)
                            .then(() => console.log("autocomplete values saved"))
                            .catch((err) => console.log("Saving autocomplete values failed", err));
                    });
                });
        }
    }, [status, orderId, productID]); // Make sure status, orderId, and productID are part of the dependency array

    let parsedData;

    try {
        parsedData = typeof customizationData === "string" ? JSON.parse(customizationData) : customizationData;
    } catch (error) {
        parsedData = {};
    }

    // set poster sizes based on tags
    useEffect(() => {
        if (orderItemData?.customization_data) {
            const tags = orderItemData?.customization_data?.tags || [];
            if (tags.length) {
                const tagDimension = tags.join(" ").match(/\d+\.?\d+x\d+\.?\d+/i); // dimensions like 8.5x11 or 11x8.5
                if (tagDimension) {
                    const [width, height] = tagDimension[0].split(/x/i).map(Number);
                    setPdfTagSize({ width, height });
                }
            }
        }
    }, [orderItemData]);

    const downloadAs = async (format) => {
        if (previewImageState.loading) {
            return;
        }
        setPreviewImageState({ loading: true });
        const productName = orderItemData?.customization_data?.productName || "file";

        if (format === "jpeg" || format === "png") {
            try {
                const base64Image = await getSmallerCanvasBase64ImageForJPEG();

                if (isMobile.iOS()) {
                    await downloadImage(base64Image, productName, "jpeg");
                    setPreviewImageState({ loading: false });
                } else {
                    let img = base64Image;
                    const a = document.createElement("a");
                    a.download = productName + "." + "jpg";
                    a.href = img;
                    a.click();
                    setPreviewImageState({ loading: false });
                }

                return;
            } catch (err) {
                console.error(err.message);
            }
        }

        if (format === "video") {
            if (isCreatingVideo) return;

            try {
                if (!videoUrlCreated) {
                    setIsCreatingVideo(true);
                    const imgNameExt = orderItemData?.customization_data?.watermarkImage || "image.jpg";
                    const song = parsedData?.song || null;

                    const videoUrl = await generateVideoAndGetURL(imgNameExt, song);
                    // actually download
                    await downloadVideo(videoUrl, productName);
                    setVideoUrlCreated(videoUrl);
                    setIsCreatingVideo(false);
                    setPreviewImageState({ loading: false });
                } else {
                    // actually download
                    console.log(videoUrlCreated, "ccccc");
                    await downloadVideo(videoUrlCreated, productName);
                    setPreviewImageState({ loading: false });
                }
            } catch (err) {
                console.error("Error creating video", err.message);
                setIsCreatingVideo(false);
            }
        }
    };

    const downloadAsPdf = async () => {
        setPreviewImageState({ loading: true });
        const productName = orderItemData?.customization_data?.productName || "download";
        await createPDFAndDownloadV2(productName, pdfTagSize);
        setPreviewImageState({ loading: false });
    };

    // dp = download page
    return (
        <div className="dp__container downloads-class page-class">
            {/* <div className="dp__title">Download your card</div> */}
            <p className="dp__title" style={{ fontSize: "34px" }}>
                Download your card
            </p>
            <p>Your media files will be sent to your email. You can also download them directly</p>
            <div id="dp__download-overlay" style={{ display: assets ? "flex" : "none" }}>
                <p
                    className="generating__files"
                    style={{
                        fontWeight: "bold",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        color: "var(--main-purple)",
                        fontSize: "18px",
                        marginBottom: "81px",
                        display: "flex",
                    }}
                >
                    {" "}
                    <Loader />
                    Generating assets... Please wait {status?.message && `(${status.message})`}
                </p>
            </div>
            <div className="dp__buttons">
                <div id="dp__download-overlay" style={{ display: previewImageState.loading ? "flex" : "none" }}>
                    <Loader width={100} height={100} />
                </div>
                {/* pdf button */}
                <div className="dp__btn-item">
                    <div
                        className={`dp__format-button ${previewImageState.loading ? "disabled" : ""}`}
                        onClick={downloadAsPdf}
                    >
                        <img src={pdfIcon} alt="pdf icon" className="dp_icon_btn_img" />
                        <div className="dp__format-text">PDF</div>
                    </div>
                    <div className="dp__btn-item-description">For print</div>
                </div>
                {/* jpg button */}
                <div className="dp__btn-item">
                    <div
                        onClick={() => downloadAs("jpeg")}
                        className={`dp__format-button ${previewImageState.loading ? "disabled" : ""}`}
                    >
                        <img src={imageIcon} alt="png icon" width={25} />
                        <div className="dp__format-text">JPG</div>
                    </div>
                    <div className="dp__btn-item-description">For screens</div>
                </div>

                {/* video button */}
                {parsedData?.song && (
                    <div className="dp__btn-item">
                        <div
                            className={`dp__format-button ${
                                previewImageState.loading || isCreatingVideo ? "disabled" : ""
                            }`}
                            onClick={() => downloadAs("video")}
                        >
                            <img src={videoIcon} alt="video icon" className="dp_icon_btn_img" />
                            <div className="dp__format-text">MP4</div>

                            {isCreatingVideo && (
                                <div id="video-load-overlay">
                                    {" "}
                                    <Spinner type="border" style={{ color: "#af40f7" }} />{" "}
                                </div>
                            )}
                        </div>

                        <div className="dp__btn-item-description">Video</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Downloads;
