import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const SubMenu = ({ subMenu, parentSlug }) => {
    return (
        <span className="sub-menu">
            {subMenu.map((item, index) => (
                <p
                    key={index}
                    className={`sub-menu-link ${item.isIcon ? "icon-true" : ""}`}
                    style={{
                        color: item?.color,
                    }}
                >
                    {item.isIcon && <span className="sub-menu-icon">{item.icon}</span>}
                    <Link
                        style={{
                            color: item?.color,
                        }}
                        className={`${item.isIcon ? "text-icon-true" : ""} sub-menu-item`}
                        to={`categories${parentSlug}?sub=${item.slug}`}
                    >
                        {item.text}
                    </Link>
                </p>
            ))}
        </span>
    );
};

export default SubMenu;
