import React from "react";

const OtherIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 83.629 71.853">
            <g id="Component_28_1" data-name="Component 28 – 1" transform="translate(1.693 1.5)">
                <path
                    id="Path_10461"
                    data-name="Path 10461"
                    d="M2056.913,785.6l-26.807-18.882c-5.21-3.67-5.21-9.619,0-13.289l26.807-18.883c5.21-3.67,13.656-3.67,18.865,0l26.808,18.883c5.209,3.67,5.209,9.619,0,13.289L2075.778,785.6C2070.569,789.269,2062.122,789.269,2056.913,785.6Z"
                    transform="translate(-2026.198 -731.793)"
                    fill="none"
                    stroke="#000"
                    stroke-miterlimit="10"
                    stroke-width="3"
                />
                <path
                    id="Path_10463"
                    data-name="Path 10463"
                    d="M2105.888,795.742c1.427,3.228.326,6.89-3.3,9.446l-26.808,18.883c-5.209,3.67-13.656,3.67-18.866,0l-26.806-18.883c-3.793-2.672-4.825-6.554-3.093-9.883"
                    transform="translate(-2026.198 -764.266)"
                    fill="none"
                    stroke="#000"
                    stroke-miterlimit="10"
                    stroke-width="3"
                />
                <path
                    id="Path_10464"
                    data-name="Path 10464"
                    d="M2105.515,807.58c1.954,3.394.977,7.428-2.93,10.181l-26.808,18.882c-5.209,3.67-13.656,3.67-18.865,0l-26.807-18.882c-3.773-2.657-4.813-6.511-3.122-9.828"
                    transform="translate(-2026.198 -770.542)"
                    fill="none"
                    stroke="#000"
                    stroke-miterlimit="10"
                    stroke-width="3"
                />
            </g>
        </svg>
    );
};

export default OtherIcon;
