import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// request
import categoriesRequests from "../../requests/categories";
import cartRequests from "../../requests/cart";
import orderRequests from "../../requests/orders";
// utils
import { getUrlQuery } from "../../utils/url";
// custom hooks
import { useIsMounted } from "../../hooks/useIsMounted";
import { useEditPurchaseHook } from "../../context/editPurchaseContext";
// icon
import closeIcon from "../../assets/images/icons/close-icon.svg";
// custom components
import SongItem from "./songItem";
import EditCheckboxButton from "../Edit/editCheckboxButton";
// mocks
// import mockupOrderData from '../../mocks/order_data_mockup';

import "./styles/songsList.css";
import { isCustomerLogin } from "../../utils/common";

const SongsList = ({ productData, onSelectSong, isEditing, setIsEditing }) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [songs, setSongs] = useState([]);
    const [selectedSongId, setSelectedSongId] = useState("");
    const [selectedSongName, setSelectedSongName] = useState("");
    const [restartAnimation, setRestartAnimation] = useState(1);
    const [checkboxDisabled, setCheckboxDisabled] = useState(false);
    const isMounted = useIsMounted();
    const { pathname } = useLocation();
    const EditPurchaseState = useEditPurchaseHook();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();

    const cart_item_key = query.get("cart_item_key");
    // reset values
    const handleResetValues = () => {
        setSelectedSongId("");
        setSelectedSongName("");
        onSelectSong(null);
    };

    // console.log(songs,"songs");

    useEffect(() => {
        if (!productData?._id) return;

        const categoryIdObj = [...(productData.categoriesIds || [])].pop();

        categoriesRequests
            .getCategorySongs(categoryIdObj._id)
            .then((_songs) => {
                if (isMounted.current) {
                    setSongs(_songs);
                }
            })
            .catch(console.log)
            .finally(() => {
                if (isMounted.current) setLoading(false);
            });
    }, [productData, isMounted]);

    // get values from woocomerce
    useEffect(() => {
        const { order_item_id, order_id } = getUrlQuery();

        // get order data
        if (order_item_id) {
            orderRequests
                .getOrderItem({
                    productId: productData._id,
                    orderId: order_id,
                })
                .then((orderData) => {
                    if (!orderData) return;

                    const songObj = orderData.customization_data?.song;
                    if (songObj) {
                        setSelectedSongId(songObj._id);
                        setSelectedSongName(songObj.name);
                        setCheckboxChecked(true);
                        onSelectSong(songObj);
                    }
                });

            return;
        }

        // get cart data
        if (cart_item_key) {
            const customerId = isCustomerLogin();

            cartRequests.getFromCartWithCartKey(cart_item_key).then((cartData) => {
                // console.log("cart_item_key song", cartData);
                if (!cartData) return;

                // const songObj = cartData.customization_data?.song;

                const firstCartItem = cartData.data[0];
                const customizationDataString = firstCartItem.customizationData;
                const customizationData = JSON.parse(customizationDataString);
                const songObj = customizationData.song;

                if (songObj) {
                    setSelectedSongId(songObj._id);
                    setSelectedSongName(songObj.name);
                    setCheckboxChecked(true);
                    onSelectSong(songObj);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (pathname.includes("edit-purchase")) {
            const { editCount, maxEditCount, songsEnabled, isTimeValid } = EditPurchaseState;
            if (songsEnabled) return;

            if (editCount < maxEditCount && isTimeValid) setCheckboxDisabled(false);
            else setCheckboxDisabled(true);
        } else setCheckboxDisabled(false);
    }, [pathname, EditPurchaseState]);

    const handleChange = (songObj) => {
        if (!isEditing) setIsEditing(true);

        if (songObj) {
            setSelectedSongId(songObj._id);
            setSelectedSongName(songObj.filename);
            onSelectSong(songObj);
        } else {
            handleResetValues();
        }
    };

    const handleShowList = (ev) => {
        const { checked } = ev.target;
        setCheckboxChecked(checked);
        setShowPopup(checked);

        if (!checked && selectedSongId) {
            handleResetValues();
        }

        if (pathname.includes("edit-purchase")) {
            const { editCount, maxEditCount, songsEnabled, enableSongs } = EditPurchaseState;
            if (songsEnabled) return;

            if (editCount < maxEditCount) {
                enableSongs(true);
            }
        }
    };

    const closePopup = () => {
        setShowPopup(false);
        if (!selectedSongId) setCheckboxChecked(false);
        const audioElmts = document.querySelectorAll(".songs-container audio");
        if (audioElmts.length) {
            audioElmts.forEach((audio) => {
                audio.pause();
                audio.currentTime = 0;
            });
        }
        // make sure the animation start from beggining
        setRestartAnimation(restartAnimation + 1);
    };

    if (loading || !songs.length) return null;

    return (
        <>
            <div className="songs-container mb-2 mb-sm-3">
                <div className="pd-checkbox">
                    <EditCheckboxButton musicOrQR="music" />
                    <div className="d-flex align-items-center">
                        <input
                            onChange={handleShowList}
                            id="add-music-check"
                            type="checkbox"
                            checked={checkboxChecked}
                            disabled={checkboxDisabled}
                            style={{
                                display:
                                    !EditPurchaseState.songsEnabled && pathname === "/edit-purchase"
                                        ? "none"
                                        : "inline-block",
                            }}
                        />
                        <label htmlFor="add-music-check">Add Music{pathname === "/edit-purchase" ? "" : " $5"}</label>
                    </div>
                </div>

                <div className="audios-container" style={{ display: showPopup ? "block" : "none" }}>
                    <div className="audios-container-close">
                        <div onClick={closePopup} className="audios-container-close-btn">
                            <img src={closeIcon} alt="close icon" />
                        </div>
                    </div>

                    <div className="audios-container-song-list custom-scroll">
                        {songs.map((songObj) => {
                            return (
                                <SongItem
                                    key={songObj._id}
                                    songObj={songObj}
                                    selectedSongId={selectedSongId}
                                    handleChange={handleChange}
                                    restartAnimation={restartAnimation}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>

            {selectedSongName && (
                <div className="selected-song">
                    <div className="selected-song-name">{selectedSongName}.mp3</div>
                    <span>Has been added</span>
                </div>
            )}
        </>
    );
};

export default SongsList;
