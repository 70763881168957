import React from 'react';
// inputs
import HebrewInput from '../../component/Inputs/HebrewInput';
import HebrewDatePicker from '../Inputs/hebrewDateInput';
import AutocompleteInput from '../Inputs/autocompleteInput';
import AutocompleteInputLikeSmartphone from '../Inputs/autocompleteInputLikeSmartphone';
// custom component
import EditTextFieldButton from '../Edit/editTextFieldButton';
// utils
import { hasHebrewCharacters } from '../../utils/text';

import './styles/inputsList.css';

function getWrapperClassName(text, isOnEditPurchasePage){
    let className = '';
    if(isOnEditPurchasePage) className += 'inputs-list-input-w';
    if(!hasHebrewCharacters(text)) className += ' english-input';
    return className;
}

const InputsList = ({ inputValues, onChange, associatedValueDisplayed, isOnEditPurchasePage = false }) => {
    // console.log(inputValues,"inputValues");
    return (
        <div id="inputs-list-wrapper">
            <div className="inputs-list-container">
                {
                    Object.values(inputValues).map( (obj, index) => {
                        const { _id, text, label, isUpperCase, type } = obj;
                        return (
                            <div key={_id || index} tabIndex={0} className={getWrapperClassName(text, isOnEditPurchasePage)}>
                                {
                                    !( (/QR/.test(label) || /group/.test(type)) ) && (
                                        <EditTextFieldButton fieldId={_id} />
                                    )
                                }

                                {/* date */}
                                {label === "date" && (
                                    <HebrewDatePicker
                                        isOnEditPurchasePage={isOnEditPurchasePage}
                                        value={text}
                                        name={_id}
                                        onChange={onChange}
                                        fieldObject={obj}
                                        isUpperCase={isUpperCase}
                                    />
                                )}
                                {/* normal text */}
                                {label === "simple text" && (
                                    <HebrewInput
                                        value={text}
                                        name={_id}
                                        onChange={onChange}
                                        isUpperCase={isUpperCase}
                                    />
                                )}
                                {/* parsha text */}
                                {label === "parsha" && (
                                    <HebrewDatePicker
                                        isOnEditPurchasePage={isOnEditPurchasePage}
                                        value={text}
                                        name={_id}
                                        onChange={onChange}
                                        parshe
                                        fieldObject={obj}
                                        isUpperCase={isUpperCase}
                                    />
                                )}
                                {/* parsha with weekday */}
                                {label === "parsha with weekday" && (
                                    <HebrewDatePicker
                                        isOnEditPurchasePage={isOnEditPurchasePage}
                                        value={text}
                                        name={_id}
                                        onChange={onChange}
                                        parshe
                                        withWeekday
                                        fieldObject={obj}
                                        isUpperCase={isUpperCase}
                                    />
                                )}
                                {/* people names */}
                                {label === "person name" && (
                                    <AutocompleteInputLikeSmartphone
                                        autocompleteLabel={label}
                                        value={text}
                                        name={_id}
                                        onChange={onChange}
                                        isUpperCase={isUpperCase}
                                    />
                                )}
                                {/* hall/shul hall address/shul address */}
                                {"hall address shul address".includes(
                                    label
                                ) && (
                                    <AutocompleteInput
                                        autocompleteLabel={label}
                                        onChange={onChange}
                                        value={text}
                                        name={_id}
                                        associatedValueDisplayed={associatedValueDisplayed}
                                        isUpperCase={isUpperCase}
                                    />
                                )}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default InputsList;