import axios from "axios";
import { API_BASE_URL, WP_API_BASE_URL } from "../config";
// utils
import { JSON2FormData } from "../utils/formData";
import { setDefaultOptions } from "../utils/common";

const orderRequests = {};

orderRequests.getOrderItem = async function ({ productId, orderId }) {
    try {
        const url = `${API_BASE_URL}/orderRequests/getOrderRequest?productId=${productId}&orderId=${orderId}`;

        // comslo
        const response = await axios.get(url, setDefaultOptions({ token: true }));

        return response.data?.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

orderRequests.updateOrderItem = async function ({ productId, orderId, data }) {
    // console.log(data ,"data");

    try {
        const url = `${API_BASE_URL}/orderRequests/updateOrderRequest?productId=${productId}&orderId=${orderId}`;
        // updateOrderRequest
        const response = await axios.post(url, data, setDefaultOptions({ token: true }));
        console.log(response.data, "updateOrderItem response.data.data");

        return response.data.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

orderRequests.getOrderItems = async function ({ customerId }) {
    const url = `${API_BASE_URL}/orders/all?customerId=${customerId}`;

    try {
        const response = await axios.get(url);

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

orderRequests.getLatestOrder = async function ({ orderId }) {
    // console.log(singleOrderId, "orderId");
    const url = `${API_BASE_URL}/orders/getLatestOrder?orderId=${orderId}`;

    console.log(url, "url");

    try {
        const response = await axios.get(url);

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

orderRequests.getOrderNewItem = async function ({ id }) {
    const url = `${API_BASE_URL}/orders/getOrder?orderId=${id}`;

    try {
        const response = await axios.get(url, setDefaultOptions({ token: true }));

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

export default orderRequests;
