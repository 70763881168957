import React from "react";

const ModalCancelIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="white" viewBox="0 0 24 24">
                <path
                    fill="none"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 5L5 19M5 5l14 14"
                    color="currentColor"
                />
            </svg>
        </div>
    );
};

export default ModalCancelIcon;
