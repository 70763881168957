import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const categoriesRequests = {};

categoriesRequests.getCategories = async function () {
    const url = `${API_BASE_URL}/categories/Categories`;

    const categories = await ajax.get(url);
    return categories;
};

categoriesRequests.getCategoryParent = async function (categoryId) {
    const url = `${API_BASE_URL}/categories/getCategoryParent/${categoryId}`;

    const category = await ajax.get(url);

    return category;
};

categoriesRequests.getCategory = async function (categoryId) {
    const url = `${API_BASE_URL}/categories/Category/${categoryId}`;
    const category = await ajax.get(url);
    return category;
};

categoriesRequests.getCategorySongs = async function (categoryId) {
    const url = `${API_BASE_URL}/categories/GetCategorySongs/${categoryId}`;
    const songs = await ajax.get(url);
    // console.log(songs,"songs");

    return songs;
};

categoriesRequests.getAllCategoriesAndSubCategories = async function () {
    const url = `${API_BASE_URL}/categories/GetAllCategoriesAndSubCategories`;
    const data = await ajax.get(url);
    return data;
};

categoriesRequests.getselectcategories = async function () {
    const url = `${API_BASE_URL}/categories/getselectCategory`;
    const data = await ajax.get(url);

    return data;
};
categoriesRequests.getOcasioncategories = async function () {
    const url = `${API_BASE_URL}/categories/getOcasioncategories`;
    const data = await ajax.get(url);

    return data;
};

export default categoriesRequests;
