import IField, { CARD_TYPE, CVV_TYPE, ACH_TYPE, THREEDS_ENVIRONMENT } from "@cardknox/react-ifields";
import React, { useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import { CARDKNOX_IFIELD_KEY, CARDKNOX_SOFTWARE_NAME, CARDKNOX_SOFTWARE_VAERSION } from "../../config";

const WrappedIfield = React.forwardRef((props, ref) => {
    const internalRef = useRef();
    useImperativeHandle(
        ref,
        () => ({
            getToken() {
                internalRef.current.getToken();
            },
            focusIfield() {
                internalRef.current.focusIfield();
            },
            clearIfield() {
                internalRef.current.clearIfield();
            },
        }),
        []
    );

    const { ifieldType, issuer, onIssuer, onToken, onError, handle3DSResults, onValidationUpdate = () => {}, hasError = false } = props;
    const account = {
        xKey: CARDKNOX_IFIELD_KEY,
        xSoftwareName: CARDKNOX_SOFTWARE_NAME,
        xSoftwareVersion: CARDKNOX_SOFTWARE_VAERSION,
    };
    const options = {
        placeholder: props.placeholder,
        enableLogging: false,
        autoFormat: true,
        autoFormatSeparator: " ",
        autoSubmit: false,
        blockNonNumericInput: true,
        iFieldstyle: {
            width: "100%",
            boxShadow: "none",
            backgroundColor: "white",
            borderRadius: "14px",
            color: "#363636",
            height: "60px",
            fontSize: "17px",
            lineHeight: "1.5",
            fontFamily: "verdana",
            border: hasError ? "2px solid #DC3545" : "2px solid #d8d7d7d5",
            boxSizing: "border-box",
            paddingLeft: "20px",
            margin: "0",
            display: "block",
            outline: "none",
            outlineOffset: "none",
            ...props.style,
        },
        // "max-width": "100%",
    };
    const onLoad = () => {
        console.log("Iframe loaded");
    };
    const onUpdate = (data) => {
        if (ifieldType === CARD_TYPE && data.issuer) onIssuer(data.issuer);

        onValidationUpdate({
            isEmpty: data.isEmpty,
            isValid: data.isValid,
            length: data.length,
        });
    };
    const onSubmit = (data) => {
        console.log("IFrame submitted", data);
    };

    const threeds = {
        enable3DS: true,
        environment: THREEDS_ENVIRONMENT.Staging,
        handle3DSResults: handle3DSResults,
    };
    return (
        <IField
            type={ifieldType}
            account={account}
            options={options}
            threeDS={ifieldType === CARD_TYPE ? threeds : null}
            onLoad={onLoad}
            onUpdate={onUpdate}
            onSubmit={onSubmit}
            onToken={onToken}
            ref={internalRef}
            onError={onError}
            issuer={issuer}
            className="ifields"
        />
    );
});
WrappedIfield.propTypes = {
    ifieldType: PropTypes.oneOf([CARD_TYPE, CVV_TYPE, ACH_TYPE]),
    issuer: PropTypes.string,
    onIssuer: PropTypes.func,
    onToken: PropTypes.func,
    onError: PropTypes.func,
    handle3DSResults: PropTypes.func,
};
export default WrappedIfield;

const submitToGateway = async () => {
    setGatewayResponse("");
    let request = {
        xSoftwareName: "Test-React-iFields",
        xSoftwareVersion: "1.0",
        xVersion: "5.0.0",
        xCommand: "cc:sale",
        xAmount: amount,
        xCardnum: cardToken,
        xBillFirstName: firstName,
        xBillLastName: lastName,
        xBillStreet: address,
        xBillCity: city,
        xBillZip: zip,
        xBillState: addressState,
        xBillMobile: mobile,
        xEmail: email,
        xExp: format(new Date(expYear, expMonth - 1), "MMyy"),
        xCvv: cvvToken,
    };
    try {
        const response = await fetch("/api/gatewayjson", {
            method: "POST",
            body: JSON.stringify(request),
            headers: { "Content-Type": "application/json" },
        });
        const responseBody = await response.json();
        setGatewayResponse(responseBody);
        if (responseBody.xResult === "V") verify3DS(responseBody);
    } catch (error) {
        console.error(error);
        setGatewayResponse(error);
    }
};
