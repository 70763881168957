import { useEffect, useState } from 'react';
import ParshaCalculator from '../../utils/parshaCalculator';
import { useLocation } from 'react-router-dom';
// custom hooks
import { useEditPurchaseHook } from "../../context/editPurchaseContext";
// utils
import { hasHebrewCharacters, addDoubleQuotes } from '../../utils/text';
import moment from "moment";
import isMobile from '../../utils/detectMobile';
import { getUrlQuery } from '../../utils/url';
// style
import './hebrewDateInput.css';

const $ = window.jQuery;

const HebrewDatePicker = ({
    name,
    value,
    onChange,
    withWeekday,
    parshe = false,
    fieldObject = {},
    isUpperCase = false,
    isOnEditPurchasePage = false,
}) => {
    const [calendarType, setCalendarType] = useState("Hebrew");
    const [show, setShow] = useState(false);
    const [isRunningOnMobile, setIsRunningOnMobile] = useState(true);
    const [disableInput, setDisableinput] = useState(false);
    const { pathname } = useLocation();

    const editPurchaseState = useEditPurchaseHook();
    const isOnEditCartPage = !!getUrlQuery()?.cart_item_key;

    const onChangeCommon = (date, date_long, isAssociatedValue = false) => {
        if (hasHebrewCharacters(date)) {
            date = addDoubleQuotes(date);
        }

        // before and after text
        const { beforeText, afterText, label } = fieldObject;
        let fullText = date;

        if (beforeText || afterText) {
            const hasHebrew = hasHebrewCharacters(beforeText || afterText);
            fullText = `${beforeText || ""} ${hasHebrew ? date : date_long} ${afterText || ""}`.trim();
        }

        if (parshe) {
            let value = ParshaCalculator.determineParsha(new Date(date_long), true);

            if (withWeekday) {
                value = `${"אבגדהוז"[moment(date_long).weekday()]}' ${value}`;
                value = value.replace("פרשת", "פר'");

                // Saturday case
                if (moment(date_long).weekday() == 6) {
                    value = value.replace("ז'", "מוצ״ש");
                }
            }
            const finalValue = `${beforeText || ""} ${value} ${afterText || ""}`.trim();
            const _autocompleteLabel = `parsha${withWeekday ? " with weekday" : ""}`;

            onChange({
                target: { name, value: finalValue },
                associatedValue: {
                    autocompleteLabel: _autocompleteLabel,
                    value: finalValue,
                    isAssociatedValue,
                },
            });
        } else {
            onChange({
                target: { name, value: fullText },
                associatedValue: {
                    autocompleteLabel: "date",
                    value: fullText,
                    isAssociatedValue,
                },
            });
        }
    };

    useEffect(() => {
        // create calendar
        const today = new Date();
        // set to friday of the current week
        let daysDiff = 5 - today.getDay();

        if (today.getDay() === 0) daysDiff = 5;

        const fridayDate = new Date(today.setDate(today.getDate() + daysDiff));
        const fridayStr = fridayDate.getMonth() + 1 + "/" + fridayDate.getDate() + "/" + fridayDate.getFullYear();

        $("#calendar" + name).jewishluach({
            calendarType: calendarType,
            date: fridayStr,
            hideAfterClick: true,
            // this function is executed once calendar is loaded
            loaded: function (calendarData) {
                const { data_herb_parsheday, data_herb_yiddishdate, date_long } = calendarData;
                const date = parshe ? data_herb_parsheday : data_herb_yiddishdate;
                $("#test").css("display", "block");
                if (isOnEditPurchasePage || isOnEditCartPage) {
                    return;
                }
                onChangeCommon(date, date_long, true);
            },
        });
    }, [calendarType, isOnEditPurchasePage, name]);

    // events
    useEffect(() => {
        const onClickOutsideComponent = (ev) => {
            const wrapper = document.querySelector("#date-wrapper" + name);
            if (wrapper && !wrapper.contains(ev.target)) {
                setShow(false);
            }
        };
        const onChangeDate = (_, data) => {
            if (!data) return;

            const { data_herb_yiddishdate, date_long } = data;
            const value = calendarType === "English" ? date_long : data_herb_yiddishdate;
            setShow(false);
            onChangeCommon(value, date_long);
        };

        // events listeners
        $("#calendar" + name).on("change", onChangeDate);
        document.body.addEventListener("click", onClickOutsideComponent);
        // remove events
        return () => {
            $("#calendar" + name).off("change");
            document.body.removeEventListener("click", onClickOutsideComponent);
        };
    }, [name, calendarType, onChange]);

    // enable or disable input depends if is on edit purchase page
    useEffect(() => {
        if (pathname.includes("edit-purchase")) {
            const { inputsTextIds } = editPurchaseState;
            const idx = inputsTextIds.indexOf(name);
            if (idx !== -1) setDisableinput(false);
            else setDisableinput(true);
        }
    }, [editPurchaseState, pathname, name]);

    useEffect(() => {
        setIsRunningOnMobile(isMobile.Any());
    }, []);

    // adjust calendar position if is screen offset
    // just for desktop (floating popup) because mobile is display block instead fixed
    useEffect(() => {
        if(!isRunningOnMobile){
            const getWrapperElmt = () => document.getElementById("date-fixed-wrapper-" + name);
    
            function adjustPosition(intersectionEntries) {
                const entry = intersectionEntries[0];
                const wrapperElmt = getWrapperElmt();
                const { top, height } = wrapperElmt.getBoundingClientRect();
    
                if(entry.intersectionRatio === 0 || entry.intersectionRatio === 1) return;
                wrapperElmt.style.top = (top - height - 55) + "px";
            }
    
            const observer = new IntersectionObserver(adjustPosition, { threshold: 0 });
            observer.observe(getWrapperElmt());
    
            return () => {
                observer.disconnect();
            }
        }
    }, [isRunningOnMobile]);

    const changeLng = (ev) => {
        setCalendarType(ev.target.value);
    };

    const handleInputClick = (ev) => {
        if(show) return;
        setShow(true);

        $("#calendar" + name).css("display", "block");
        if(!isRunningOnMobile){
            let top = ev.target.getBoundingClientRect().y;
            $("#date-fixed-wrapper-" + name).css("top", top + 50 + "px");
        }
    };

    const handleOnChange = (ev) => {
        onChange({
            target: { name, value: ev.target.value },
            associatedValue: {},
        });
    };

    return (
        <div className="hebrew-date-wrapper" id={"date-wrapper" + name}>
            <input
                type="text"
                name={name}
                value={value}
                className="k-input-in-form"
                tabIndex={0}
                autoComplete="off"
                onClick={handleInputClick}
                onChange={handleOnChange}
                disabled={disableInput}
                style={{
                    direction: hasHebrewCharacters(value) ? "rtl" : "ltr",
                    textTransform: isUpperCase ? "uppercase" : "none",
                }}
            />

            <div
                style={{ 
                    display: (show && !disableInput) ? "block" : "none",
                    position: isRunningOnMobile ? "relative" : "fixed",
                    marginTop: isRunningOnMobile ? "-20px" : "0px"
                }}
                id={"date-fixed-wrapper-" + name}
                className="date-fixed-wrapper"
            >
                <div className="hebrew-calendar-type-selection">
                    <div>
                        <input
                            type="radio"
                            name="calendarType"
                            id={"calendar1" + name}
                            value="English"
                            onChange={changeLng}
                            checked={calendarType === "English"}
                        />
                        <label htmlFor={"calendar1" + name}>English</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name="calendarType"
                            id={"calendar2" + name}
                            value="Hebrew"
                            onChange={changeLng}
                            checked={calendarType === "Hebrew"}
                        />
                        <label htmlFor={"calendar2" + name}>Hebrew</label>
                    </div>
                </div>
                <div id={"calendar" + name}></div>
            </div>
        </div>
    );
};

export default HebrewDatePicker;