import React from 'react';
import checkboxIcon from '../../assets/images/icons/checkbox-icon.svg';

// this component is a div acting as a input radio button
const FakeRadioButton = ({ checked, onCheck, className }) => {
    const handleCheck = () => {
        // uncheck all the previous buttons
        const radiosBtns = document.querySelectorAll('.' + className);
        if(radiosBtns.length){
            radiosBtns.forEach( btn => btn.classList.remove('active'))
        }
        onCheck(!checked);
    }

    return (
        <div
            onClick={handleCheck} 
            className={`${className} ${checked ? ' active' : ''}`}
        >   
            { checked && <img src={checkboxIcon} alt="checkbox icon" /> }
        </div>
    );
}
 
export default FakeRadioButton;