/**
 * Here I'm using fitty.js to calculate font size depending of box width
 */

import fitty from 'fitty';
import { wait } from './wait';


const calculateFontSize = async (txtObj, text) => {
    const {
        width, 
        scaleX, 
        fontSize, 
        maxFontSize,
        fontFamily, 
        fontWeight, 
        _id, 
        _textLines,
        multiLine,
        originalTextLength,
        originalLinesLength
    } = txtObj;

    const realWidth = width * scaleX;
    const realFontSize = fontSize * scaleX;

    // insert element if doesn't exists
    if(!document.querySelector('#fitty-' + _id)){
        const wrapper = document.createElement('div');
        const elmt = document.createElement('div');
        wrapper.style.width = realWidth + 'px';
        wrapper.style.visibility = 'hidden';
        wrapper.style.position = 'fixed';
        wrapper.style.top = '-9999999px';
        wrapper.id = 'fitty-' + _id;

        elmt.style.fontFamily = fontFamily;
        elmt.style.fontWeight = fontWeight;
        elmt.style.fontSize = realFontSize;
        elmt.setAttribute('data-originalFontSize', maxFontSize);
        elmt.classList.add('text-inside-wrapper');

        wrapper.append(elmt);
        document.body.append(wrapper);
        // test
        // document.getElementById('test-resize').append(wrapper);
    }

    const txtDiv = document.querySelector(`#fitty-${_id} .text-inside-wrapper`);

    txtDiv.textContent = text;

    const originalFontSize =  Number(txtDiv.getAttribute('data-originalFontSize')) * scaleX;
    let minSize = 8;

    // set font minSize for multiline text
    if(multiLine){
        const txtLengthThreshold = 12;

        if(
            _textLines.length > originalLinesLength 
            || 
            text.length > originalTextLength + txtLengthThreshold
        ){
            minSize = originalFontSize - 4;
        } else {
            minSize = originalFontSize;
        }
    }

    // fit text and return data
    let result = fitty(`#fitty-${_id} .text-inside-wrapper`, {
        maxSize: originalFontSize,
        minSize,
        multiLine,
    });
    // wait a little bit for fitty.js modify text in the DOM
    await wait(15);

    const newFontSizeStr = result[0].element.style.fontSize.replace('px', '');
    return Number(newFontSizeStr);
}


export {
    calculateFontSize
}