import axios from "axios";
import { getToken } from "../utils/token";
import { API_BASE_URL } from "../config";
const request = {};

const setDefaultOptions = ({ headers, token, cancelToken }) => ({
    headers: {
        ...headers,
        "Content-type": "application/json",
        ...(token && { authorization: `Bearer ${getToken()}` }),
    },

    ...(cancelToken && { cancelToken }),
});

const manageResponse = (responseDataObj) => {
    const { error, body, status } = responseDataObj;

    if (error) {
        console.error(`${body} \nstatus: ${status}`);
        return `${body} \nstatus: ${status}`;
        throw new Error(body);
    }

    return body;
};

request.get = async function (url, options = {}) {
    try {
        const { data } = await axios.get(url, setDefaultOptions(options));

        return manageResponse(data);
    } catch (err) {
        console.error(err);
        throw new Error(err.message);
    }
};

request.post = async function (url, dataToSend, options = {}) {
    try {
        const { data } = await axios.post(url, dataToSend, setDefaultOptions(options));
        return manageResponse(data);
    } catch (err) {
        console.error(err);
        throw new Error(err.message);
    }
};

request.verifyToken = async function (token, customer_id) {
    const url = `${API_BASE_URL}/auth/VerifyTokenCustomer/${customer_id}`;
    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error verifying token:", error);
        return false;
    }
};

export default request;
