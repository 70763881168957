import axios from "axios";
import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const authRequests = {};
authRequests.login = async function (email, password) {
    const url = `${API_BASE_URL}/auth/Login`;

    const sessionData = await ajax.post(url, {
        email,
        password,
    });
    return sessionData;
};

authRequests.getCustomer = async function (email, password) {
    const url = `${API_BASE_URL}/customer/get-customer`;

    const sessionData = await ajax.post(url, {
        email,
        password,
    });
    return sessionData;
};
authRequests.signUp = async function (userData) {
    const url = `${API_BASE_URL}/customer/signup`;

    try {
        const response = await axios.post(url, userData.formData);

        if (response.data.token) {
            const verify = authRequests.verifyToken(response.data.token);
            // isAuthenticated = false;
            if (verify) {
                localStorage.setItem("token-jwt", response.data.token);
                localStorage.setItem("customerEmail", response.data.customer.email);
                const isAuthenticated = true;
                return isAuthenticated;
            }
        } else {
            throw new Error("No token received");
        }
    } catch (error) {
        if (error.response) {
            console.error("Signup error:", error.response.data.message);
            throw new Error(`Signup error: ${error.response.data.message}`);
        } else {
            console.error("Signup error:", error.message);
            throw new Error(`Signup error: ${error.message}`);
        }
    }
};

authRequests.customerLogin = async function (email, password) {
    const url = `${API_BASE_URL}/auth/customerLogin`;

    try {
        const response = await axios.post(url, {
            email,
            password,
        });
        console.log("response", response);
        if (response.data.token) {
            const verify = await authRequests.verifyToken(response.data.token);
            if (verify) {
                await localStorage.setItem("token-jwt", response.data.token);
                await localStorage.setItem("customerEmail", response.data.customer.email);
                return response.data.token;
            } else {
                throw new Error("No token received");
            }
        } else {
            throw new Error("No data received");
        }
    } catch (error) {
        if (error.response) {
            console.error("Login error:", error.response.data.message);
            throw new Error(`Login error: ${error.response.data.message}`);
        } else {
            console.error("Login error:", error.message);
            throw new Error(`Login error: ${error.message}`);
        }
    }
};

authRequests.customerLoginGoogle = async function (token) {
    const url = `${API_BASE_URL}/auth/customerLoginGoogle`;

    const payload = { token };

    try {
        const sessionData = await ajax.post(url, JSON.stringify(payload), {
            headers: { "Content-Type": "application/json" },
        });

        const verify = authRequests.verifyToken(sessionData.token);

        console.log("verify", verify);
        if (verify) {
            localStorage.setItem("token-jwt", sessionData.token);
            localStorage.setItem("customerEmail", sessionData.customer.email);

            const isAuthenticated = true;
            return isAuthenticated;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Failed to login with Google:", error);
        throw error;
    }
};

authRequests.customerLogout = async function () {
    localStorage.removeItem("token-jwt");
    localStorage.removeItem("customerId");
    return true;
};

authRequests.forgotPassword = async function ({ email }) {
    const url = `${API_BASE_URL}/auth/forgotPassword`;
    try {
        const response = await axios.post(url, { email });

        return response;
    } catch (error) {
        if (error.response) {
            console.error("Error:", error.response.data.message);
            throw new Error(`Error: ${error.response.data.message}`);
        } else {
            console.error("Error:", error.message);
            throw new Error(`Error: ${error.message}`);
        }
    }
};

authRequests.resetPassword = async function ({ token, password }) {
    const url = `${API_BASE_URL}/customer/reset-password`;

    try {
        const request = await axios.post(url, JSON.stringify({ token, password }), {
            headers: { "Content-Type": "application/json" },
        });
        const { data } = request;

        return data.message;
    } catch (error) {
        console.error("something went wrong:", error);
        throw error;
    }
};

authRequests.verifyToken = async function (token) {
    const url = `${API_BASE_URL}/customer/verify-token`;

    try {
        const response = await axios.post(url, { token });
        const { customer } = response.data;

        localStorage.setItem("customerId", JSON.stringify(customer._id));

        return response.data.isValid;
    } catch (error) {
        // localStorage.removeItem("customerId");
        // localStorage.removeItem("token-jwt");
        // localStorage.removeItem("customerEmail");
        console.error("Failed to verify token:", error);
        throw error;
    }
};

export default authRequests;
