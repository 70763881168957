import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
// custom hooks
import { useEditPurchaseHook } from "../../context/editPurchaseContext";

// show this button when is in edit purchase page
const EditTextFieldButton = ({ fieldId }) => {
    const { pathname } = useLocation();
    const { enableTextField, inputsTextIds, editCount, maxEditCount, isTimeValid } = useEditPurchaseHook();
    const [show, setShow] = useState(false);

    const handleEnable = () => {
        console.log("editCount", editCount);
        console.log("maxEditCount", maxEditCount);
        if (editCount < maxEditCount && isTimeValid) enableTextField(fieldId);
    };

    useEffect(() => {
        if (pathname.includes("edit-purchase")) setShow(true);
        else setShow(false);
    }, [pathname]);

    if (!show) return null;
    if (inputsTextIds.includes(fieldId)) return null;

    return (
        <div className="enable-button-textfield">
            <Button
                onClick={handleEnable}
                icon="edit"
                color="#bf5ade"
                type="primary"
                shape="circle"
                className="edit-p-btn"
                disabled={editCount >= maxEditCount || !isTimeValid}
            />
        </div>
    );
};

export default EditTextFieldButton;
