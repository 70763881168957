import React from "react";

const CustomModalSendIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20.026 18.037">
                <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M18.037,20.026h-7.3L9.018,7.349,7.3,20.026H0L9.018,0l9.018,20.026Z"
                    transform="translate(20.026) rotate(90)"
                    fill="#fff"
                />
            </svg>
        </div>
    );
};

export default CustomModalSendIcon;
