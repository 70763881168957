import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Downloadspdf from "./Downloadspdf";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";

import Downloadsjpg from "./Downloadsjpg";
import { isCustomerLogin } from "../../utils/common";
import orderRequests from "../../requests/orders";
import customerRequest from "../../requests/customer";
import "./style/downloads.css";
import productRequests from "../../requests/products";
import OrderContent from "./OrderContent";
import Downloads from "./downloads";
const DownloadModal = ({ isOpen, status, closeModalDownload, singleOrderId }) => {
    const [order, setOrder] = useState([]);
    const [orderId, setOrderId] = useState();
    const [products, setProdcuts] = useState({});
    const [productID, setProductID] = useState(false);

    const [viewOrder, setViewOrder] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [downloadOptions, setDownloadOptions] = useState(false);
    // Fetch orders

    console.log("singleOrderId", singleOrderId);
    const fetchOrders = useCallback(async () => {
        if (!singleOrderId) return;
        const customerId = isCustomerLogin();
        if (!customerId) return;
        try {
            const obj = await orderRequests.getLatestOrder({ orderId: singleOrderId });

            console.log(obj.data, "obj");
            setOrder(obj.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    }, [singleOrderId]);

    // Fetch customer profile
    const fetchProfile = useCallback(async () => {
        const customerId = localStorage.getItem("customerId");
        if (!customerId) return;
        try {
            const userProfile = await customerRequest.getCustomer({ customerId });
            // console.log(userProfile, "userProfile");
            if (userProfile) setCustomer(userProfile);
        } catch (error) {
            console.error("Error fetching profile:", error);
        }
    }, []);

    // async function fetchProducts(order) {
    //     const items = order.items;
    //     const result = [];

    //     for (let i = 0; i < items.length; i++) {
    //         const product = await productRequests.getProduct({ productId: items[i].productId });

    //         result.push(product);
    //     }

    //     setProdcuts({ ...products, [order._id]: result });
    // }

    useEffect(() => {
        if (isOpen && singleOrderId) {
            fetchOrders();
            fetchProfile();
        }
    }, [isOpen, singleOrderId, fetchOrders, fetchProfile]);

    const onDownload = (e, product, productOrderId) => {
        const productimageid = product?.productId;
        setDownloadOptions(true);
        setProductID(productimageid);
        setOrderId(productOrderId);
    };

    function onViewOrder(data) {
        // console.log(data, "data");

        if (products[data._id] == null) {
            fetchProducts(data);
        }
        setViewOrder(data);

        // toggle();
    }

    // useEffect(() => {
    //     fetchOrders();
    //     fetchProfile();
    // }, [isOpen, fetchOrders, fetchProfile]);
    const handleModalClose = () => {
        // Notify parent by invoking the callback
        closeModalDownload();
    };
    const getTotalPrice = (order) => order.items.reduce((total, item) => total + item.price, 0);

    const formatDate = (date) =>
        new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });

    const closeBtn = (
        <div className="modal-close-btn" onClick={handleModalClose}>
            <ModalCancelIcon />
        </div>
    );

    return (
        <Modal isOpen={isOpen} className="modal-dialog-centered" toggle={handleModalClose}>
            <ModalHeader toggle={handleModalClose} close={closeBtn}>
                Thank you for your purchase
            </ModalHeader>
            <ModalBody className=" modal-downalod">
                <div className="dp__container downloads-class page-class">
                    <div className="dp__title">Downloads</div>
                    <div className="dp__buttons">
                        <Container>
                            <Row>
                                <Col lg={12} className="mt-4 pr-5">
                                    <Row>
                                        <Col lg={12}>
                                            {!viewOrder ? (
                                                <div className="table-responsive">
                                                    {order.length > 0 ? (
                                                        <table className="table orderhistory-table mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order</th>
                                                                    <th>Date</th>

                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {order.map((Ordervalue) =>
                                                                    Ordervalue.items.map((item, index) => (
                                                                        <tr key={`${Ordervalue.orderId}-${index}`}>
                                                                            <td>{Ordervalue.orderId}</td>
                                                                            <td>{formatDate(Ordervalue.updatedAt)}</td>
                                                                            {/* Display product ID if needed */}
                                                                            <td>
                                                                                <button
                                                                                    style={{
                                                                                        border: "none",
                                                                                        background:
                                                                                            "var(--main-purple)",
                                                                                        color: "#fff",
                                                                                        padding: "8px 23px",
                                                                                        width: "100%",
                                                                                        fontWeight: "500",
                                                                                        borderRadius: "8px",
                                                                                    }}
                                                                                    className="download-btn"
                                                                                    onClick={(e) =>
                                                                                        onDownload(
                                                                                            e,
                                                                                            item,
                                                                                            Ordervalue.orderId
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="14px"
                                                                                        height="14px"
                                                                                        viewBox="0 0 24 24"
                                                                                    >
                                                                                        <path
                                                                                            fill="currentColor"
                                                                                            d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-8 4v-5h2v3h12v-3h2v5z"
                                                                                        />
                                                                                    </svg>{" "}
                                                                                    Download
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <p>No orders available.</p>
                                                    )}
                                                </div>
                                            ) : (
                                                <>
                                                    {/* {(products[viewOrder._id] ?? []).map((product) => (
                                                        <OrderContent
                                                            key={product.id}
                                                            product={product}
                                                            orderId={viewOrder.orderId}
                                                        />
                                                    ))} */}
                                                    {/* Additional order details */}
                                                </>
                                            )}

                                            <Modal
                                                isOpen={downloadOptions}
                                                toggle={() => setDownloadOptions(false)}
                                                backdrop="static"
                                            >
                                                {/* {console.log("Modal is rendering with state:", downloadOptions)} */}
                                                <ModalHeader toggle={() => setDownloadOptions(false)}></ModalHeader>
                                                <ModalBody className="modal-download modal-dialog-centered">
                                                    <Downloads
                                                        productID={productID}
                                                        orderId={orderId}
                                                        status={status}
                                                    />
                                                </ModalBody>
                                            </Modal>
                                        </Col>
                                    </Row>
                                    <p className="mb-0 mt-3"><span className="fw-bold">Note:</span> You have up to 72 hours to make edits, with a limit of 3 changes in total. Once you reach either limit, further edits won’t be possible.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DownloadModal;
