import { API_BASE_URL } from "../config";
// utils
import axios from "axios";
import { setDefaultOptions } from "../utils/common";
import { redirect } from "react-router-dom";

const cartRequests = {};

cartRequests.saveInCart = async function (body) {
    const url = `${API_BASE_URL}/cart/addItemToCart`;

    try {
        const response = await axios.post(url, { body });
        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

cartRequests.getFromCartGuest = async function (guestUserId) {
    const url = `${API_BASE_URL}/cart/getCartItemsGuest?guestUserId=${guestUserId}`;

    try {
        const response = await axios.get(url, setDefaultOptions({ token: true }));
        return response.data;
    } catch (error) {
        console.error("Failed to add item to Cart: ", error);
        throw error;
    }
};

cartRequests.updateCartCustomerId = async function ({ guestUserId, customerId }) {
    const url = `${API_BASE_URL}/cart/updateCartCustomerId`;

    try {
        const response = await axios.post(url, { guestUserId, customerId }, setDefaultOptions({ token: true }));
        return response.data;
    } catch (error) {
        console.error("Failed to update cart with customer ID: ", error);
        throw error;
    }
};

cartRequests.getFromCart = async function (customerId) {
    const url = `${API_BASE_URL}/cart/getCartItems?customerId=${customerId}`;
    try {
        const response = await axios.get(url);
        // console.log(response,"response get cartitems");
        return response.data;
    } catch (error) {
        console.error("Failed to add item to Cart: ", error);
        throw error;
    }
};

cartRequests.getFromCartWithCartKey = async function (cart_item_key) {
    const url = `${API_BASE_URL}/cart/getCartItemsCartId?cart_item_key=${cart_item_key}`;

    try {
        const response = await axios.get(url);
        // console.log(response,"response get cartitems");
        return response.data;
    } catch (error) {
        console.error("Failed to add item to Cart: ", error);
        throw error;
    }
};

cartRequests.deleteFromCart = async function (itemid) {
    const url = `${API_BASE_URL}/cart/deleteFromCart`;

    try {
        const response = await axios.post(url, { itemid }, setDefaultOptions({ token: true }));
        return response.data;
    } catch (error) {
        console.error("Failed to delete from Cart: ", error);
        throw error;
    }
};

cartRequests.clearCart = async function (customerId) {
    const url = `${API_BASE_URL}/cart/clearCart`;

    try {
        const response = await axios.post(url, { customerId }, setDefaultOptions({ token: true }));

        if (response.status === 200) {
            // window.location.href = "/account/OrderHistory";
        } else {
            console.log("Failed to clear Cart: ", response);
        }
        // redirect to /accunt/OrderHistory
    } catch (error) {
        console.error("Failed to clear Cart: ", error);
        throw error;
    }
};

export default cartRequests;
