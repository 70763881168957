import React, { useRef, useState } from "react";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import Attachment from "../../assets/icons/Attachment";
import CustomModalSendIcon from "../../assets/CustomModalSendIcon";
import mailRequests from "../../requests/mail";
import CircleLoader from "../Loader/circleLoader";
import Swal from "sweetalert2";
import "../../component/TermsAndConditions/style.css";

const CustomModal = ({ customModalOpen, setCustomModalOpen }) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [message, setMessage] = useState("");
    const [validationErrors, setValidationErrors] = useState({});
    const [formStatus, setFormStatus] = useState("");
    const [modalLoading, setModalLoading] = useState(false);

    const handleButtonClick = () => fileInputRef.current.click();

    const resetForm = () => {
        setName("");
        setEmail("");
        setDate("");
        setMessage("");
        setFile(null);
        setValidationErrors({});
        setFormStatus("");
    };

    async function onSubmit() {
        const errors = {};
        if (!name) errors.name = "Name is required.";
        if (!email) {
            errors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid email format.";
        }
        if (!message) errors.message = "Message is required.";
        if (!date) errors.date = "Date is required.";

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            setFormStatus("error");
            return;
        }
        setValidationErrors({});
        Swal.fire({
            title: "Submitting request",
            html: "Please wait while we process your request.",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const response = await mailRequests.sendCustomRequestMail({ name, email, message, date, file });
            if (response?.data?.error === false) {
                Swal.close();
                Swal.fire({
                    title: "Request Successful",
                    icon: "success",
                    text: "Your request was processed successfully.",
                    timer: 2000,
                    showConfirmButton: false,
                });
                setFormStatus("success");
                
                closeModal();
            } else {
                throw new Error("Failed to submit request");
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: "Error",
                icon: "error",
                text: error.message || "Failed to submit request",
                timer: 2000,
                showConfirmButton: false,
            });
            setFormStatus("error");
        }
    }

    const closeModal = () => {
        setCustomModalOpen(false);
    
        // Remove Bootstrap modal classes
        const modalElement = document.getElementById("customModal");
        if (modalElement) {
            modalElement.classList.remove("show");
            modalElement.style.display = "none";
        }
    
        // Remove modal backdrop if it exists
        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
            backdrop.parentNode.removeChild(backdrop);
        }
    
        // Remove Bootstrap's modal-open class from the body
        document.body.classList.remove("modal-open");
        document.body.style.overflow = ""; // Reset overflow style
        document.body.style.paddingRight = ""; // Reset padding

        resetForm(); // Reset all form fields when closing
    };
    
    

    const handleFileInputChange = (e) => {
        setFile(e.target.files[0]);
    };

    const dateInputRef = useRef(null);

    const handleClick = () => {
        if (dateInputRef.current) {
            dateInputRef.current.showPicker();
        }
    };

    return (
        <div
            className={`modal fade ${customModalOpen ? "show" : ""}`}
            id="customModal"
            style={{ display: customModalOpen ? "block" : "none" }}
            data-bs-backdrop="true"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden={!customModalOpen}
        >
            <div className="modal-dialog scrresetForm();ollable reach-out-modal-container modal-dialog-centered">
                <div className="modal-content">
                    <div
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close-btn"
                        onClick={() => setCustomModalOpen(false)}
                    >
                        <ModalCancelIcon />
                    </div>

                    <div className="modal-body">
                        <p className="reach-out-hd custom-modal-hd">Custom Design</p>
                        <div className="reach-modal-input-wrap custom-modal-input-wrap">
                            <div>
                                <input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="reach-input"
                                    type="text"
                                    required
                                    placeholder="Name"
                                />
                                {validationErrors.name && <p className="error-message">{validationErrors.name}</p>}
                            </div>
                            <div>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="reach-input"
                                    type="email"
                                    required
                                    placeholder="Email"
                                />
                                {validationErrors.email && <p className="error-message">{validationErrors.email}</p>}
                            </div>
                            <div>
                                <div className="textarea-icon-wrap">
                                    <textarea
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Hi, I need a poster for..."
                                        className="reach-out-text-area custom-modal-textarea"
                                    ></textarea>

                                    <div className="reach-out-btn-wrap custom-modal-btn-wrap">
                                        <div className="date-and-file-wrap">
                                            <button className="custom-modal-attchment-btn" onClick={handleButtonClick}>
                                                <Attachment />
                                                <span>Add attachment</span>
                                            </button>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: "none" }}
                                                onChange={handleFileInputChange}
                                            />
                                            <div className="date-input-container">
                                                <div className="date-trigger" onClick={handleClick}>
                                                    <div style={{width: "20px", height: "28px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z"/></svg></div>
                                                </div>
                                                <input
                                                onClick={handleClick}
                                                    ref={dateInputRef}
                                                    type="date"
                                                    value={date}
                                                    onChange={(e) => setDate(e.target.value)}
                                                    className={`date-input position-relative ${date ? 'filled-date' : ''}`}
                                                    placeholder="dd/mm/yyyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="custom-modal-submit-btn-wrap">
                                            <button onClick={onSubmit} className="custom-modal-submit-btn">
                                                Submit <CustomModalSendIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {validationErrors.message && <p className="error-message">{validationErrors.message}</p>}
                                {validationErrors.date && <p className="error-message">{validationErrors.date}</p>}
                            </div>
                            {formStatus === "success" && (
                                <div className="response-output" aria-hidden="false">
                                    Thank you for your message. It has been sent.
                                </div>
                            )}
                            {formStatus === "error" && (
                                <div className="response-output error" aria-hidden="false">
                                    One or more fields have an error. Please check and try again.
                                </div>
                            )}
                            <div className="custom-modal-not-wrap">
                                <p className="custom-modal-note">
                                    <span className="note-hd">Please Note:</span> Your invitation will be custom
                                    designed for you and you will be the first one to use it. Once your Simcha has
                                    passed, it will be available for purchase in the template section.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
