// product detail page
import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { fabric } from "fabric";
// request
import fieldsRequests from "../../requests/fields";
import productRequests from "../../requests/products";
import cartRequests from "../../requests/cart";
// utils
import {
    setCanvasAtResoution,
    assingObjectsToCanvas,
    setCustomFontsWhenLoaded,
    forceRenderUpdate,
    toggleAllFieldsVisibility,
} from "../../utils/canvas";
import { hasHebrewCharacters, reverseNumbers, convertNameToFriendlyUrlName } from "../../utils/text";
import { getUrlQuery } from "../../utils/url";
import { calculateFontSize } from "../../utils/fontResize";
import postToParent from "../../utils/postToParent";
import { setCursorCaret } from "../../utils/inputCursorCaret";
// custom components
import InputsList from "./inputs-list";
import CustomLoader from "../Loader/customLoader";
import SongsList from "./songsList";
import QrCodeInput from "./qrCode";
import CardPreview from "./preview";
import AddToCartAndBuyNowButtons from "../Cart/addToCartBuyNowButtons";
// constants
import { CANVAS_SIZE } from "../../utils/constants";
// style
import "./styles/productDetail.css";
import Footer from "../../layouts/footer/Footer";
import { isCustomerLogin } from "../../utils/common";

const ProductDetail = () => {
    const { productId } = useParams();
    // console.log(productId ,"product image");

    const history = useNavigate();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState({});
    const [cartItemKey, setCartItemKey] = useState("");

    // const [productFields, setProductFields] = useState([]);
    const [canvas, setCanvas] = useState();
    const [inputsValues, setInputsValues] = useState({});
    const [songSelected, setSongSelected] = useState(null);
    const [associatedValueDisplayed, setAssociatedValueDisplayed] = useState(false);
    const [QRText, setQRText] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [enablePreview, setEnablePreview] = useState(false);
    const query = useQuery();
    const cart_item_key = query.get("cart_item_key");

    // console.log(canvas,"input image");

    const changeAndResizeText = async (txtObj, newText, isAssociatedValue = false) => {
        const _textTransformUpperCase = (txt) => {
            return txtObj.isUpperCase ? (txt || "").toUpperCase() : txt;
        };

        setInputsValues((previousState) => ({
            ...previousState,
            [txtObj._id]: {
                ...previousState[txtObj._id],
                text: _textTransformUpperCase(newText),
            },
        }));

        const newFontSize = await calculateFontSize(txtObj, newText);

        const isHebrew = hasHebrewCharacters(newText);
        const text = isHebrew ? reverseNumbers(newText) : newText;
        let extraCount = 0;

        txtObj.set({
            text: _textTransformUpperCase(text),
            fontSize: newFontSize / txtObj.scaleX,
            direction: isHebrew ? "rtl" : "ltr",
            borderColor: "#af52de",
        });

        // if text isn't multiLine and has 2 lines, reduce fontSize to fit in one line
        while (!txtObj.multiLine && txtObj._textLines.length > 1) {
            // reduce font size 5%
            txtObj.fontSize *= 0.95;
            canvas.renderAll();
            // console.log("reducing to single line");
        }

        // if text is multiLine and has more lines than original, reduce fontSize to fit
        // in original lines quantity
        while (txtObj.multiLine && txtObj._textLines.length > txtObj.originalLinesLength) {
            // reduce font size 5%
            txtObj.fontSize *= 0.95;
            canvas.renderAll();
            // console.log("reducing lines to fit multiple lines");
        }

        // reduce font if box width is bigger than max width (specially for multiline boxes)
        // using a variable called "extraCount" to checking box width, because if you reduce the box width
        // and the text is still very large (fontSize) the box width  won't change
        // so we need to remember the last fontSize
        extraCount = txtObj.fontSize;

        while (txtObj.width > txtObj.maxWidth) {
            extraCount--;
            txtObj.fontSize = extraCount;
            canvas.renderAll();
            txtObj.width = txtObj.maxWidth;
            canvas.renderAll();
            // console.log("reducing box width and font size to fit maxWidth");
        }

        if (!isAssociatedValue) {
            txtObj.borderColor = "#af52de";
            // txtObj.borderColor = "red";
            canvas.setActiveObject(txtObj);
        }
        canvas.renderAll();
    };

    const handleChange = async (ev) => {
        if (!isEditing) setIsEditing(true);

        const { name: fieldId, value: fieldText } = ev.target;
        const { associatedValue, inputElmt, selectionStart } = ev;

        const currentCaretPosition = selectionStart;
        setCursorCaret(inputElmt, "hide");
        const canvasObjs = canvas.getObjects();

        const txtObj = canvasObjs.find((obj) => obj._id === fieldId);
        await changeAndResizeText(txtObj, fieldText, associatedValue?.isAssociatedValue);

        // if the field has an associated value like hall name with hall address
        // or shul name with shul address, update it.
        if (associatedValue) {
            setAssociatedValueDisplayed(true);

            const associatedTxtObj = canvasObjs.find((obj) => obj.label === associatedValue.autocompleteLabel);
            if (associatedTxtObj) {
                await changeAndResizeText(associatedTxtObj, associatedValue.value, true);
            }
        } else {
            setAssociatedValueDisplayed(false);
        }
        // show input cursor again
        setCursorCaret(inputElmt, "show", currentCaretPosition);
    };

    // redirect or reload page when item is deleted from cart (for example, you delete this item from cart in edit item page)
    const redirectIfDeletedFromCart = async () => {
        try {
            const { id: wooProductId, customizer_product_id } = getUrlQuery();
            const product = await productRequests.getProduct({
                productId: customizer_product_id,
                wooProductId,
            });
            if (!product?.categoriesIds?.length) {
                postToParent({ type: "navigate", to: `design-your-card/?id=${wooProductId}` });
            } else {
                const productCategoryName = product.categoriesIds[0].name;
                postToParent({
                    type: "navigate",
                    to: `cards/?id=${convertNameToFriendlyUrlName(productCategoryName)}`,
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const { customizer_product_id, id: wooProductId } = productId ? {} : getUrlQuery();
        // init canvas
        const _canvas = new fabric.Canvas("canvas-wrapper", {
            selection: false,
            imageSmoothingEnabled: false,
        });
        setCanvas(_canvas);

        console.log();

        if (cart_item_key) {
            setCartItemKey(cart_item_key);
        }

        // get product fields from wordpress cart or backend api
        async function _getProductFields() {
            let _productFields;
            const customerId = isCustomerLogin();
            if (cart_item_key) {
                const cartData = await cartRequests.getFromCartWithCartKey(cart_item_key);

                if (cartData) {
                    const firstCartItem = cartData?.data[0];

                    const customizationDataString = firstCartItem.customizationData;
                    const customizationData = JSON.parse(customizationDataString);

                    // if (customizationData.canvasObjects) {
                    //     console.log("Cart item not found");
                    //     await redirectIfDeletedFromCart();
                    //     return;
                    // }
                    _productFields = customizationData.canvasObjects;
                }
                // console.log(cartData,"cartDate");
            } else {
                _productFields = await fieldsRequests.getProductFields(productId || customizer_product_id);
                // console.log("productFieldsimage", _productFields);
            }

            return _productFields;
        }

        async function resizeAndSetTextOnCanvas() {
            const _productFields = await _getProductFields();
            if (_productFields === undefined || _productFields.length === 0) return false;

            const imageDimensions = {
                width: _productFields[0].originalImageWidth,
                height: _productFields[0].originalImageHeight,
            };

            const _inputsValues = {};
            for (let f of _productFields) {
                _inputsValues[f._id] = f;
            }

            // setProductFields(_productFields);
            setInputsValues(_inputsValues);

            await assingObjectsToCanvas(_canvas, _productFields);
            toggleAllFieldsVisibility(_canvas, false);
            setCanvasAtResoution(_canvas, CANVAS_SIZE, _productFields[0].canvasWidth, imageDimensions);
            return true;
        }

        function setCanvasBackgroundImage(_productData, callback) {
            _canvas.setDimensions({ width: CANVAS_SIZE, height: CANVAS_SIZE });

            return new Promise((resolve) => {
                fabric.Image.fromURL(
                    _productData.filesId.pathWithWatermark,
                    function (img) {
                        const scaleFactor = img.width / _canvas.width;
                        const naturalCanvasHeight = img.height / scaleFactor;
                        _canvas.setDimensions({ width: _canvas.width, height: naturalCanvasHeight });

                        _canvas.setBackgroundImage(img, _canvas.renderAll.bind(_canvas), {
                            scaleX: _canvas.width / img.width,
                            scaleY: _canvas.height / img.height,
                        });

                        callback();
                        resolve();
                    },
                    {
                        crossOrigin: "anonymous",
                        objectCaching: false,
                    }
                );
            });
        }

        // get data like name and price
        async function getProductData() {
            const _product = await productRequests.getProduct({
                productId: productId || customizer_product_id,
                wooProductId,
            });
            setProduct(_product);
            return _product;
        }

        // run functions
        getProductData().then((_productData) => {
            resizeAndSetTextOnCanvas().then(() => {
                setCustomFontsWhenLoaded(_canvas).then(async () => {
                    await forceRenderUpdate(_canvas);
                });

                setCanvasBackgroundImage(_productData, () => {
                    forceRenderUpdate(_canvas, true);
                    setEnablePreview(true);
                    toggleAllFieldsVisibility(_canvas);
                    setCanvas(_canvas);
                });

                setIsLoading(false);
            });
        });
    }, [productId]);

    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener("contextmenu", handleContextMenu);

        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    }, []);

    // window.canvas = canvas; // for development save

    return (
        <>
            <div className="product-detail-wrapper content-full product-detail-class page-class position-relative">
                <div className="pd-flex-container">
                    {isLoading && (
                        <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                            <CustomLoader width={40} height={40} />
                        </div>
                    )}
                    <div className="pd-left-column">
                        <div className="pd-go-back" onClick={() => history(-1)}>
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                            <span>Back</span>
                        </div>

                        <div id="test-resize"></div>

                        <div className="pd-canvas-container">
                            <canvas id="canvas-wrapper"></canvas>
                            {/* {isLoading && <CustomLoader width={40} height={40} />} */}
                        </div>
                    </div>

                    {!isLoading && (
                        <div className="pd-right-column">
                            {isLoading ? (
                                <CustomLoader width={40} height={40} />
                            ) : (
                                <>
                                    <div className="pd-nameprice">
                                        <h4>{product.name}</h4>
                                        <h3>
                                            <b>${product.price}</b>
                                        </h3>
                                    </div>

                                    <InputsList
                                        onChange={handleChange}
                                        inputValues={inputsValues}
                                        associatedValueDisplayed={associatedValueDisplayed}
                                    />

                                    <hr id="pd-hr" />

                                    <div className="pd-bottom-btn">
                                        <div className="pd-checkboxes">
                                            <SongsList
                                                productData={product}
                                                onSelectSong={setSongSelected}
                                                setIsEditing={setIsEditing}
                                                isEditing={isEditing}
                                            />

                                            <div className="pd-qr-preview">
                                                <QrCodeInput
                                                    canvas={canvas}
                                                    setQRText={setQRText}
                                                    setIsEditing={setIsEditing}
                                                    isEditing={isEditing}
                                                />

                                                <CardPreview canvas={canvas} enabled={enablePreview} />
                                            </div>
                                        </div>

                                        <AddToCartAndBuyNowButtons
                                            productDetails={{ canvas, product, songSelected, QRText }}
                                            isEditing={isEditing}
                                            isPreviewAvailable={enablePreview}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ProductDetail;
