import React, { useState } from "react";
import SimchaResImg from "../../assets/simchaInvitaion/simcha-res-img.png";
import "./styles/simcha-invitation.css";
import CustomModal from "./CustomModal";

const SimchaInvitation = () => {
    const [customModalOpen, setCustomModalOpen] = useState(false);

    console.log(customModalOpen, "customModalOpen");

    return (
        <>
            <div className="simcha-main-container" id="custom-design">
                <div className="simcha-container">
                    <div className="simcha-text-hd-wrap">
                        <p className="simcha-hd">It’s not every day that you make a Simcha.</p>
                        <div className="simcha-text-wrap">
                            <p className="simcha-text">
                                You want an invitation as unique as your occasion and a templated invite just won’t cut
                                it.
                            </p>
                            <p className="simcha-text">
                                The maven in you wants an invitation that is custom designed for your special day.
                            </p>
                        </div>
                        <p className="simcha-sub-hd">We get it. And we’ll get it done.</p>
                        <button className="simcha-button text-button" data-bs-toggle="modal"
                                data-bs-target="#customModal" onClick={() => setCustomModalOpen(true)}>
                            Can I Have It Custom?
                        </button>
                    </div>
                    <div className="simcha-img-wrap">
                        <img className="simcha-img" src={SimchaResImg} alt="Simcha" />
                        <img className="simcha-res-img" src={SimchaResImg} alt="Simcha Responsive" />
                        <button className="simcha-button img-button" data-bs-toggle="modal"
                                data-bs-target="#customModal" onClick={() => setCustomModalOpen(true)}>
                            Can I Have It Custom?
                        </button>
                    </div>
                </div>
            </div>
            {/* <CustomModal customModalOpen={customModalOpen} setCustomModalOpen={setCustomModalOpen} /> */}
        </>
    );
};

export default SimchaInvitation;
