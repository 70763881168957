import React, { createContext, useContext, useState, useEffect } from "react";
import authRequests from "../../requests/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem("token-jwt");
            if (token !== null && token !== undefined && token !== "") {
                const isValid = await authRequests.verifyToken(token);
                setAuthenticated(isValid);
            } else {
                setAuthenticated(false);
            }
            setLoading(false);
        };

        verifyToken();
    }, []);

    const login = async (credentials) => {
        const result = await authRequests.login(credentials);

        if (result?.isAuthenticated) {
            console.log("Result: ", result);
            localStorage.setItem("token-jwt", result.token);
            setAuthenticated(true);
        }
    };

    const logout = () => {
        localStorage.removeItem("token-jwt");
        setAuthenticated(false);
        authRequests.customerLogout();
    };

    return (
        <AuthContext.Provider value={{ authenticated, setAuthenticated, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
