const DIMENSIONS = ['Vertical (8.5x11)', 'Horizontal (11x8.5)', 'Square (8.5x8.5)'];

function findDimensionsTag(productTags) {
    if(!productTags) return '';
    if(!productTags.length) return '';

    const tagsString = productTags.join(' ');
    
    for (const dimension of DIMENSIONS) {
        if(tagsString.includes(dimension)){
            return dimension;
        }
    }

    return '';
}

export {
    findDimensionsTag
}